import axios from 'axios';
import { post } from '../service_config/http.service';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

export async function login(email, password) {
  if (!email || !password) {
    throw new Error('email and password required for login');
  }

  try {
    let response = await instance.post('/user/login', { email, password });
    localStorage.setItem('baeon_user', JSON.stringify(response.data.payload));
    return response;
  } catch (error) {
    return {
      msg: 'Failed',
      statusCode: error.response.status,
      payload: error.response.data.payload,
    };
  }
}

export async function register(
  name,
  businessName,
  email,
  password,
  termsAccepted,
  refUserId
) {
  if (!name || !businessName || !email || !password || !termsAccepted) {
    return 'all fields required';
  }

  try {
    let response = await instance.post('/user/register', {
      name,
      businessName,
      email,
      password,
      termsAccepted,
      refUserId,
    });
    localStorage.setItem('baeon_user', JSON.stringify(response.data.payload));
    return response;
  } catch (error) {
    return {
      msg: 'Failed',
      statusCode: error.response.status,
      payload: error.response.data.payload,
    };
  }
}

export function logout() {
  localStorage.removeItem('baeon_user');
  window.open('/auth/login');
}

export function getCurrentUser() {
  return JSON.parse(localStorage.getItem('baeon_user'));
}

export function isAuthenticated() {
  const user = getCurrentUser();
  if (user?.authToken) return true;
  return false;
}

export const genrateOtp = async (data) => {
  try {
    const res = await post(`auth/send-otp`, data);
    return res.data;
  } catch (e) {
    return e.response.data;
  }
};
export const verifyOtp = async (data) => {
  try {
    const res = await post(`auth/verify-otp`, data);
    localStorage.setItem('baeon_user', JSON.stringify(res.data.result));
    return res.data;
  } catch (e) {
    return e.response.data;
  }
};
