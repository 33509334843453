import { Box, Card, Link, makeStyles } from '@material-ui/core';
import Container from '@mui/material/Container';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Logo from '../assets/baeon-full.jpg';
import {
  getStoreDetail,
  getUserDetails,
} from '../service_config/admin.service';
import { getCurrentUser } from '../service_config/auth.service';

const useStyles = makeStyles((theme) => ({
  main: {
    // backgroundImage: `url(${Image})`,
    position: 'relative',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    // background: "#141414",
    background: '#014f8e',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    // maxWidth: "500px",
    margin: '0 auto',
  },
  card: {
    maxWidth: '300px',
    minHeight: '300px',
    padding: '30px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  businessCard: {
    maxWidth: '700px',
    minHeight: '300px',
    padding: '30px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  imgDiv: {
    PaddingBottom: '30px',
  },
  navLinks: {
    display: 'flex',
    justifyContent: 'end',
    flexWrap: 'wrap',
    color: '#FFFFFF',
    gap: '30px',
    padding: '20px',
    fontSize: '20px',
  },
}));

export default function Layout(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const checkforStores = async () => {
    let responseData = await getStoreDetail();
    if (!responseData.error && responseData.result?.length > 0) {
      navigate('/home');
    } else if (!responseData.error && responseData.result?.length === 0) {
      navigate(`/create-store/add-store`);
    }
  };
  const checkforuser = async () => {
    let responseData = await getUserDetails();
    if (!responseData.error && responseData.result?.businessId) {
      checkforStores();
    } else if (!responseData.error) {
      navigate(`/info`);
    }
  };

  React.useEffect(() => {
    if (getCurrentUser()?.authToken) {
      checkforuser();
    }
  }, []);

  return (
    <div className={classes.main}>
      <div className={classes.navLinks}>
        <Link
          href="https://baeonn.com/"
          color="inherit"
          target="_blank"
          rel="noopener"
        >
          Home
        </Link>
        <Link
          href="https://baeonn.com/contact.html"
          color="inherit"
          target="_blank"
          rel="noopener"
        >
          Contact us
        </Link>
      </div>
      <Container component="main" maxWidth="md" className={classes.container}>
        <Box className={classes.content}>
          <Card
            variant="outlined"
            color="primary"
            className={props.business ? classes.businessCard : classes.card}
          >
            <div className={classes.imgDiv}>
              <img src={Logo} width="200px" height="80px" alt="logo" />
            </div>
            {props.children}
          </Card>
        </Box>
        <ToastContainer></ToastContainer>
      </Container>
    </div>
  );
}
