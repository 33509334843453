import { useEffect, useState } from 'react';
import logo from '../../../assets/Partnership-bro.svg';
import NumberInputForm from './NumberInputForm';
import OtpInputForm from './OtpInputForm';
import { useNavigate, useSearchParams } from 'react-router-dom';
import showToast from '../../../utilities/toast';
import {
  genrateOtp,
  getCurrentUser,
  verifyOtp,
} from '../../../service_config/auth.service';
import { getStoreDetail } from '../../../service_config/admin.service';
import { BUSINESSOWNER } from '../../../utilities/constants';

const Login = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [phone, setPhone] = useState('');
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const invitationToken = searchParams.get('token');
  const referredUserId = searchParams.get('refUser');
  const referralCode = searchParams.get('refCode');

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call initially to set the state based on the initial window size

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  //redirect to home if user is already logged in
  useEffect(() => {
    if (getCurrentUser()?.authToken) {
      navigate('/home');
    }
  }, []);

  const _makeOtpRequest = (number) => {
    return genrateOtp({
      mobileNumber: number,
      ...(invitationToken && { invitationToken }),
    });
  };

  const _makeOtpVerificationRequest = (otp) => {
    return verifyOtp({ mobileNumber: phone, otp });
  };

  const onNumberSubmit = (number) => {
    if (!number || number.length < 10) {
      showToast('Please enter a valid phone number', 'error');
      return;
    }
    setPhone(number);
    _makeOtpRequest(number)
      .then((response) => {
        if (response.error) {
          showToast('Somethig went wrong, try again', 'error');
          return;
        }
        setShowOtpForm(true);
        showToast(`OTP sent successfully to: ${number}`, 'success');
      })
      .catch((_error) => {
        setShowOtpForm(false);
        showToast('Somethig went wrong, try again', 'error');
      });
  };

  const handleNavigation = (response) => {
    if (response.result.businessId) {
      if (getCurrentUser().role.name === BUSINESSOWNER) {
        getStoreDetail()
          .then((response) => {
            if (!response.error && response.result.length > 0) {
              navigate(`/home`);
            } else {
              navigate(`/create-store`);
            }
          })
          .catch((_error) => {
            navigate(`/create-store`);
          });
      } else {
        navigate(`/store`);
      }
    } else {
      let url = '/info';
      if (referredUserId && referralCode) {
        url = `${url}?refUser=${referredUserId}&refCode=${referralCode}`;
      }
      navigate(url);
    }
  };

  const onOtpSubmit = (otp) => {
    if (!otp || otp.length <= 0) {
      showToast('Please enter a valid OTP', 'error');
      return;
    }
    _makeOtpVerificationRequest(otp)
      .then((response) => {
        if (response.error) {
          showToast(response.msg, 'error');
          return;
        }
        handleNavigation(response);
        showToast(`OTP verified successfully`, 'success');
      })
      .catch((_error) => {
        showToast('Somethig went wrong, try again', 'error');
      });
  };

  const onReEnter = () => {
    setShowOtpForm(false);
  };

  const onReSend = () => {
    onNumberSubmit(phone);
  };

  const handleGenerateQR = () => {
    const qrValue = 'Baeonn';
    navigate(`/generate-qr?qrValue=${qrValue}`);
  };

  return (
    <div
      className={`flex items-center justify-center min-h-screen ${isMobile ? 'bg-none' : 'md:bg-cover md:bg-center'}`}
      style={{ backgroundImage: isMobile ? 'none' : `url(${logo})` }}
    >
      {!showOtpForm ? (
        <NumberInputForm
          onSubmit={onNumberSubmit}
          generateQr={handleGenerateQR}
        />
      ) : (
        <OtpInputForm
          onSubmit={onOtpSubmit}
          reEnter={onReEnter}
          reSend={onReSend}
        />
      )}
    </div>
  );
};

export default Login;
