import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import CardBankDetails from '../../components/Cards/CardBankDetails';
import CardBusinessDetails from '../../components/Cards/CardBusinessDetails';
import MainLayout from '../../layout/mainLayout';

const useStyles = makeStyles((theme) => ({
  lableTitle: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontWeight: 'bold',
    color: '#57748a',
  },
  lable: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontWeight: 'bold',
    color: '#57748a',
  },
  cards: {
    marginTop: '100px',
  },

  card: {
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
    borderRadius: '5px',
    padding: '20px',
  },
  canc: {
    background: 'blue',
    color: 'white',
    fontWeight: '700',
    '&:hover': {
      background: 'blue',
      color: 'white',
    },
  },
  inputfile: {
    display: 'none',
  },
  upload: {
    background: '#f0f3f5',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  radio: {
    '&$checked': {
      color: '#4B8DF8',
    },
  },
  checked: {},
}));
export default function Business() {
  const classes = useStyles();

  return (
    <>
      <MainLayout>
        <Grid container spacing={2} className={classes.cards}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
              borderRadius: '5px',
              padding: '20px',
            }}
          >
            <CardBusinessDetails />
          </Grid>
          <Grid item xs={12} md={6}>
            <CardBankDetails />
          </Grid>
        </Grid>
      </MainLayout>
    </>
  );
}
