import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import MainLayout from '../../layout/mainLayout';

import { Box, Card, makeStyles, Typography } from '@material-ui/core';

import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import DorllarImage from '../../assets/dollar.png';
import { getCredit, getCreditPoints } from '../../service_config/admin.service';

const useStyles = makeStyles((theme) => ({
  cards: {
    marginTop: '100px',
  },
  card: {
    width: '100%',
    padding: '50px',
    minHeight: '100vh',
  },
  imagediv: {
    width: '100%',
    position: 'absolute',
    top: '100px',
    display: 'flex',
    justifyContent: 'center',
    zIndex: '10',
  },
  image: {
    width: '150px',
    height: '100%',
    objectFit: 'cover',
  },
  count: {
    color: 'red',
    fontSize: '50px',
    fontWeight: 'bold',
  },
  link: {
    color: 'red',
    fontSize: '20px',
  },
  title: {
    fontSize: '25px',
    fontWeight: 'bold',
  },
  shareButton: {
    marginRight: '100px',
  },
}));

export default function CreditAndRef() {
  const classes = useStyles();
  const [creditPoint, setCreditPoint] = useState(0);
  const data = useSelector((state) => state.credit.ref);
  const GETURL = () => {
    return `${process.env.REACT_APP_WEB_BASE_URL}login?refUser=${data.id}&refCode=${data.referralCode}`;
  };
  const title = 'Acquire and retain customers at ease with BAEON.';
  const hashtag = 'baeon';

  const getCreditPointsDetails = async () => {
    const data = await getCreditPoints();
    if (!data.error) {
      setCreditPoint(
        data.result?.business?.creditsUtilizationPercentage?.current
      );
    } else {
      setCreditPoint(0);
    }
  };
  React.useEffect(() => {
    getCredit();
    getCreditPointsDetails();
  }, []);

  return (
    <>
      <MainLayout>
        <div className={classes.cards}>
          <Card variant="outlined">
            <div className={classes.imagediv}>
              <img src={DorllarImage} alt="dollar" className={classes.image} />
            </div>
            <Box
              sx={{
                marginTop: '130px',
                maxWidth: '900px',
                margin: '0 auto',
                // background: "#94e7f2",
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  margin: '0 auto',
                  background: '#94e7f2',
                  textAlign: 'center',
                  padding: '30px',
                }}
              >
                <Typography
                  variant="h5"
                  display="block"
                  sx={{
                    // fontSize: "12px",
                    lineHeight: '1rem',
                    fontWeight: 'bold',
                    color: 'green',
                  }}
                >
                  Total Point Earned
                </Typography>
                <Typography
                  variant="h5"
                  display="block"
                  className={classes.count}
                >
                  {creditPoint ? creditPoint : 0}
                </Typography>
              </Box>
              <Box
                sx={{
                  marginTop: '50px',
                  width: '100%',
                  margin: '0 auto',
                  background: '#94e7f2',
                  textAlign: 'center',
                  padding: '30px',
                }}
              >
                <Typography
                  variant="h5"
                  display="block"
                  className={classes.title}
                >
                  Referall Code for Friends
                </Typography>
                <Typography
                  variant="h5"
                  display="block"
                  className={classes.link}
                >
                  <a href={GETURL()} target="_blank" rel="noreferrer">
                    {`${process.env.REACT_APP_WEB_BASE_URL}login?refUser=${data.id}&refCode=${data.referralCode}`}
                  </a>
                </Typography>
              </Box>

              <Box
                sx={{
                  marginTop: '50px',
                  width: '100%',
                  margin: '0 auto',
                  background: '#c9c5c5',
                  textAlign: 'center',
                  padding: '30px',
                }}
              >
                <Typography
                  variant="h5"
                  display="block"
                  className={classes.title}
                >
                  Share with your frieds
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    maxWidth: '300px',
                    justifyContent: 'space-between',
                    margin: '30px auto',
                  }}
                >
                  <FacebookShareButton
                    url={GETURL()}
                    hashtag={hashtag}
                    quote={title}
                    className="shareButton "
                  >
                    <FacebookIcon size={50} round />
                  </FacebookShareButton>

                  <TwitterShareButton
                    url={GETURL()}
                    hashtags={[hashtag]}
                    title={title}
                    className="shareButton "
                  >
                    <TwitterIcon size={50} round />
                  </TwitterShareButton>

                  <TelegramShareButton
                    url={GETURL()}
                    title={title}
                    className="shareButton"
                  >
                    <TelegramIcon size={50} round />
                  </TelegramShareButton>

                  <WhatsappShareButton
                    url={GETURL()}
                    title={title}
                    separator=":: "
                    className="shareButton"
                  >
                    <WhatsappIcon size={50} round />
                  </WhatsappShareButton>

                  <LinkedinShareButton url={GETURL()} className="shareButton">
                    <LinkedinIcon size={50} round />
                  </LinkedinShareButton>
                </Box>
              </Box>
            </Box>
          </Card>
        </div>
      </MainLayout>
    </>
  );
}
