import { useEffect, useRef, useState } from 'react';
import Profile from '../assets/logo.jpeg';

const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const OnClosed = ({ togglePopup, isOpen, user }) => {
  return (
    <button
      onClick={togglePopup}
      className="flex items-center bg-white text-white px-6 py-2 rounded-full z-10"
    >
      {user.profileImage || Profile ? (
        <img
          src={user?.profileImage || Profile}
          alt="Profile"
          className="w-8 h-8 rounded-full mr-2"
        />
      ) : (
        <div className="w-8 h-8 rounded-full bg-white border border-gray-300 flex items-center justify-center mr-2">
          <i className="fas fa-user text-gray-500"></i>
        </div>
      )}
      <span className="mr-2 text-black">
        {capitalizeFirstLetter(user.firstName) || 'User'}
      </span>
      <i
        className={`fas ${isOpen ? 'fa-chevron-up' : 'fa-chevron-down'} text-black`}
      ></i>
    </button>
  );
};

const OnOpen = ({ user, logout }) => {
  return (
    <div className="absolute right-0 top-full mt-2 w-48 bg-white border border-gray-300 rounded shadow-lg">
      <div className="p-4">
        <p className="text-gray-600">{user.email}</p>
        <ul className="mt-2">
          {user.settings ? (
            <li
              onClick={() => alert('Settings clicked')}
              className="cursor-pointer mt-2 text-gray-800 hover:text-blue-500"
            >
              Settings
            </li>
          ) : (
            ''
          )}

          <li
            onClick={logout}
            className="cursor-pointer mt-2 text-red-500 hover:text-red-700"
          >
            Logout
          </li>
        </ul>
      </div>
    </div>
  );
};

const ProfilePopup = ({ user, logout }) => {
  const [isOpen, setIsOpen] = useState(false);
  const popupRef = useRef(null);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div
      className="relative hidden md:flex justify-end items-center mb-10"
      ref={popupRef}
    >
      <OnClosed togglePopup={togglePopup} isOpen={isOpen} user={user} />

      {isOpen && <OnOpen user={user} logout={logout} />}
    </div>
  );
};

export default ProfilePopup;
