import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  typography: {
    fontFamily: [
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
    ].join(','),
    fontSize: 15,
    lineHeight: 34,
    fontWeight: 500,
    color: '#fcba03',
  },
  components: {
    MuiButton: {
      defaultProps: {
        // The props to change the default for.
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: '1rem',
          background: '#152894',
          color: '#f7f8fa',
          fontWeight: '700',
          '&:hover': {
            background: '#152894',
            color: '#f7f8fa',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fieldset: {
            borderRadius: 15,
          },
        },
      },
    },
    // MuiOutlinedInput: {
    // defaultProps: {
    //   // The props to change the default for.
    //   disableRipple: true, // No more ripple, on the whole application 💣!
    // },
    // styleOverrides: {
    //   // Name of the slot
    //   root: {
    //     // Some CSS
    //     fontSize: '1rem',
    //     background:"red",
    //     color:"#f7f8fa",
    //     "&:hover": {
    //       // backgroundColor: "#152894",
    //       // color:"#f7f8fa",
    //     },
    //   },
    // },

    // },
  },
});

// margin: 0;
//     color: #1c1c1c;
//     font-size: 20px;
//     line-height: 1rem;
//     font-weight: 500;
//     margin-top: 24px;
//     margin-bottom: 24px
// px
// ;
//     margin-right: auto;
