import * as React from 'react';
import { useEffect } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { yupResolver } from '@hookform/resolvers/yup';
import { FormControl, Radio, RadioGroup } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Controller, useForm } from 'react-hook-form';
import ErrorField from '../../components/errorField';
import TableSelect from '../../components/table/selectTable';

import {
  Button,
  Card,
  Chip,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Checkbox from '@mui/material/Checkbox';
import moment from 'moment';
import MainLayout from '../../layout/mainLayout';

import Stack from '@mui/material/Stack';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import companyLogo from '../../assets/ill_header.png';

import { useState } from 'react';
import Lable from '../../components/Typography/lable';
import {
  createCampaign,
  getEstimateFee,
  getMediaPartner,
  getStoreDetail,
} from '../../service_config/admin.service';
import { getCurrentUser } from '../../service_config/auth.service';
import {
  campaignFormArray,
  campaignInitialState,
  campaignSchema,
} from './formValues/creatCampaign';

const useStyles = makeStyles((theme) => ({
  lable: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontWeight: 'bold',
    color: '#57748a',
  },
  card: {
    maxWidth: '1000px',
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
    minHeight: '100vh',
    margin: '0px auto',
    marginTop: '100px',
    borderRadius: '5px',
    padding: '20px',
  },
  cancel: {
    background: '#152894',
    color: '#f7f8fa',
    fontWeight: '700',
    '&:hover': {
      background: '#152894',
      color: '#f7f8fa',
    },
  },
  inputfile: {
    display: 'none',
  },
  upload: {
    background: '#f0f3f5',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  icon: {
    transform: 'rotate(180deg)',
  },
  grid: {
    padding: '25px 0',
  },
  input: {
    border: 'none',
    boxShadow: '0 3px 5px rgb(0 0 0 / 0.1)',
    background: 'white',
    borderRadius: '10px',
    '&:hover': {
      border: 'none',
      outline: 'none',
    },
  },
  noteLable: {
    fontSize: '0.8125rem',
    backgroundColor: '#e0e0e0',
    borderRadius: '30px',
    padding: '8px',
    width: 'fit-content',
  },
  warning: {
    fontSize: '0.8125rem',
    backgroundColor: 'red',
    borderRadius: '30px',
    padding: '8px',
    width: 'fit-content',
    color: 'white',
  },
}));

function CreateCampaign(props) {
  const [rows, setRows] = React.useState([]);
  const classes = useStyles();
  const [estimateFeeData, setEstimatedFee] = useState({});
  const [selectedList, setSelectedList] = useState([]);
  const campaign = useSelector((state) => state.campaignAdd.addCampaign);
  const mediaPartner = useSelector((state) => state.campaignAdd.mediaPartner);
  const stores = useSelector((state) => state.storeDetail.stores);
  let navigate = useNavigate();
  const [predefinedCouponSelected, setPredefinedCouponSelected] =
    useState(false);
  const [DealPoolSelected, setDealPoolSelected] = useState(false);
  const [campaignOption, setCampaignOption] = useState('Deal Pool');

  const cityData = {
    'Andaman and Nicobar Islands': [
      'Port Blair',
      'Diglipur',
      'Mayabunder',
      'Rangat',
    ],
    'Andhra Pradesh': ['Visakhapatnam', 'Vijayawada', 'Guntur', 'Nellore'],
    'Arunachal Pradesh': ['Itanagar', 'Tawang', 'Ziro', 'Bomdila'],
    Assam: ['Guwahati', 'Dispur', 'Tezpur', 'Silchar'],
    Bihar: ['Patna', 'Gaya', 'Bhagalpur', 'Muzaffarpur'],
    Chandigarh: ['Chandigarh'],
    Chhattisgarh: ['Raipur', 'Bilaspur', 'Bhilai', 'Korba'],
    'Dadra and Nagar Haveli and Daman and Diu': ['Daman', 'Diu', 'Silvassa'],
    Delhi: ['New Delhi'],
    Goa: ['Panaji', 'Vasco da Gama', 'Margao', 'Mapusa'],
    Gujarat: ['Ahmedabad', 'Surat', 'Vadodara', 'Rajkot'],
    Haryana: ['Chandigarh', 'Faridabad', 'Gurgaon', 'Panipat'],
    'Himachal Pradesh': ['Shimla', 'Manali', 'Dharamshala', 'Dalhousie'],
    'Jammu and Kashmir': ['Srinagar', 'Jammu', 'Leh', 'Katra'],
    Jharkhand: ['Ranchi', 'Jamshedpur', 'Dhanbad', 'Bokaro'],
    Karnataka: ['Bengaluru', 'Mysuru', 'Hubballi', 'Mangaluru'],
    Kerala: ['Thiruvananthapuram', 'Kochi', 'Kozhikode', 'Thrissur'],
    Ladakh: ['Leh', 'Kargil'],
    Lakshadweep: ['Kavaratti'],
    'Madhya Pradesh': ['Bhopal', 'Indore', 'Jabalpur', 'Gwalior'],
    Maharashtra: ['Mumbai', 'Pune', 'Nagpur', 'Nashik'],
    Manipur: ['Imphal', 'Thoubal', 'Bishnupur', 'Churachandpur'],
    Meghalaya: ['Shillong', 'Tura', 'Jowai', 'Nongpoh'],
    Mizoram: ['Aizawl', 'Lunglei', 'Saiha', 'Champhai'],
    Nagaland: ['Kohima', 'Dimapur', 'Tuensang', 'Mokokchung'],
    Odisha: ['Bhubaneswar', 'Cuttack', 'Rourkela', 'Berhampur'],
    Puducherry: ['Puducherry', 'Karaikal', 'Mahe', 'Yanam'],
    Punjab: ['Chandigarh', 'Ludhiana', 'Amritsar', 'Jalandhar'],
    Rajasthan: ['Jaipur', 'Jodhpur', 'Udaipur', 'Kota'],
    Sikkim: ['Gangtok', 'Namchi', 'Mangan', 'Jorethang'],
    'Tamil Nadu': ['Chennai', 'Coimbatore', 'Madurai', 'Tiruchirappalli'],
    Telangana: ['Hyderabad', 'Warangal', 'Nizamabad', 'Karimnagar'],
    Tripura: ['Agartala', 'Dharmanagar', 'Udaipur', 'Kailasahar'],
    'Uttar Pradesh': ['Lucknow', 'Kanpur', 'Varanasi', 'Agra'],
    Uttarakhand: ['Dehradun', 'Haridwar', 'Rishikesh', 'Mussoorie'],
    'West Bengal': ['Kolkata', 'Asansol', 'Siliguri', 'Durgapur'],
  };

  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
    setSelectedCity('');
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    getValues,
    watch,
    reset,
    formState: { errors, values },
  } = useForm({
    defaultValues: { ...campaignInitialState },
    resolver: yupResolver(campaignSchema),
    mode: 'onBlur',
  });

  useEffect(() => {
    const newEndDate = moment(getValues('startDate')).add(7, 'days');
    setValue('endDate', newEndDate);
  }, [getValues('startDate')]);

  useEffect(() => {
    const newExpiryDate = moment(getValues('endDate')).add(7, 'days');
    setValue('coupons.expiryDate', newExpiryDate);
  }, [getValues('endDate')]);

  const campaignDetails = watch();

  const setSelectedRows = (selectedValue) => {
    if (campaignDetails?.campaignType === 'Targeted Campaign') {
      setValue('mediaPartners', selectedValue);
      setError('mediaPartners', { type: 'custom', message: '' });
    } else {
      setValue('campaignPincodes', selectedValue);
      setError('campaignPincodes', { type: 'custom', message: '' });
    }
  };
  const getTimeStamp = (data) => {
    let formatDate = moment(data).format('YYYY-MM-DD');
    const date = new Date(formatDate);
    const timestamp = date.getTime();
    return String(timestamp);
  };

  const submitDetails = async () => {
    const copyCampaingn = { ...campaignDetails };

    let media = copyCampaingn.mediaPartners.map((mediaId) => {
      return { id: mediaId, businessId: getCurrentUser().businessId };
    });

    copyCampaingn.mediaPartners = media;
    let dataformat = {
      ...copyCampaingn,
      coupons: {
        ...copyCampaingn.coupons,
        expiryDate: getTimeStamp(copyCampaingn.coupons.expiryDate),
      },
      startDate: getTimeStamp(copyCampaingn.startDate),
      endDate: getTimeStamp(copyCampaingn.endDate),
    };

    const response = await createCampaign(dataformat);
    if (!response.error) {
      toast.success(response.msg);
      if (dataformat?.campaignType === 'Deal Pool') {
        navigate(`/home`);
      } else {
        navigate(`/campaignPayment`, {
          state: {
            campaignId: response.result.campaignId,
            couponsCount: campaign.coupons.total,
          },
        });
      }
    } else {
      toast.error(response.msg);
    }
  };
  useEffect(() => {
    if (
      campaignDetails.mediaPartners.length >= 3 &&
      campaignDetails.coupons.total > 0
    ) {
      getEstimateAmount();
    }

    if (campaignDetails.mediaPartners.length < 3) {
      // setValue("mediaPartners", []);
      setEstimatedFee({});
      setSelectedList([]);
      setValue('mediaPartnersSplitCoupons', []);
    }
  }, [campaignDetails.mediaPartners, campaignDetails.coupons.total]);

  const onChangeCoupons = (value, id) => {
    let changedValue = selectedList?.map((item) => {
      if (item?.mediaPartner?.id === id) {
        return { ...item, noOfCoupons: parseInt(value) };
      }
      return item;
    });
    setSelectedList(changedValue);
    setValue('mediaPartnersSplitCoupons', changedValue);
    setError('mediaPartnersSplitCoupons', { type: 'custom', message: '' });
  };
  const getEstimateAmount = async (value = '') => {
    let resData = await getEstimateFee({
      advertiser: {
        businessId: getCurrentUser().id,
        storeId: campaignDetails.storeId,
      },
      numberOfCoupons: campaignDetails.coupons.total,
      mediaPartnerIds: campaignDetails.mediaPartners,
      isCouponChange:
        value === 'couponsChange' ? campaignDetails.isCouponChange : false,
      mediaPartnersSplitCoupons: campaignDetails.mediaPartnersSplitCoupons,
    });
    if (!resData.error) {
      setEstimatedFee(resData.result);
      setSelectedList(resData.result.mediaPartners);
      setValue('mediaPartnersSplitCoupons', resData.result.mediaPartners);
    }
  };

  React.useEffect(() => {
    //store.dispatch(mediaPartnerReducer([]));
    getStoreDetail();
  }, []);

  React.useEffect(() => {
    if (
      campaignDetails.storeId &&
      campaignDetails?.campaignType === 'Targeted Campaign'
    ) {
      getMediaPartner({ storeId: campaignDetails.storeId });
    } else if (campaignDetails.storeId) {
      getMediaPartner({ storeId: campaignDetails.storeId });
    }
  }, [campaignDetails.storeId, campaignDetails.campaignType]);
  React.useEffect(() => {
    let row = [];
    for (let i = 0; i < mediaPartner.length; i++) {
      let obj = {
        id:
          campaignDetails.campaignType === 'Targeted Campaign'
            ? mediaPartner[i].id
            : mediaPartner[i]?.address?.zipcode,
        domain: mediaPartner[i].category,
        // distance: 32,
        mediapartner: mediaPartner[i].branchName,
        converstionrate: mediaPartner[i].mediaPartnerFee.bonus.couponClaimRate,
        sharingrate: mediaPartner[i].mediaPartnerFee.bonus.couponShareRate,
        fee: mediaPartner[i].mediaPartnerFee.grandTotal,
        logo: mediaPartner[i].logoUrl ? mediaPartner[i].logoUrl : companyLogo,
        pincode: mediaPartner[i]?.address?.zipcode,
        city: mediaPartner[i]?.address?.city,
        state: mediaPartner[i]?.address?.state,
      };
      row.push(obj);
    }
    setRows(row);
  }, [mediaPartner]);

  const setimageUrl = (data, fileType) => {
    setValue('coupons.imageUrl', data);
    setError('coupons.imageUrl', { type: 'custom', message: '' });
  };

  const onSubmit = (data) => {
    if (data === 'Estimate') {
      getEstimateAmount('couponsChange');
    } else {
      submitDetails();
    }
  };

  // const handleChange = () =>{

  // }

  return (
    <MainLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card variant="outlined" className={classes.card}>
          <Grid
            container
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            className={classes.grid}
          >
            <Grid item>
              <Typography
                variant="h6"
                display="block"
                sx={{
                  // fontSize: "12px",
                  lineHeight: '1rem',
                  fontWeight: 'bold',
                  color: '#1c1c1c',
                }}
                className={classes.lable}
              >
                Create a new Campaign
              </Typography>
            </Grid>
          </Grid>{' '}
          <Stack>
            <Lable
              style={{ my: 2, marginRight: 'auto' }}
              title="SELECT STORE"
            />
            <Controller
              name={'storeId'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <Select
                    // onChange={handleChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    fullWidth
                    variant="outlined"
                    value={value}
                    onChange={onChange}
                  >
                    <MenuItem value="">
                      <em>Select Store</em>
                    </MenuItem>
                    {stores?.map((store, index) => (
                      <MenuItem value={store?.id} key={index}>
                        {store?.branchName}
                      </MenuItem>
                    ))}
                  </Select>{' '}
                </>
              )}
            />
            {errors?.storeId && <ErrorField err={errors?.storeId?.message} />}
          </Stack>
          {campaignDetails.storeId && mediaPartner?.length > 0 ? (
            <>
              <Stack spacing={2} sx={{ p: 2 }}>
                {campaignFormArray.map((data, index) => {
                  let { grid, items } = data;

                  if (grid) {
                    return (
                      <div key={index}>
                        <Grid container spacing={2}>
                          {items.map((field, itemIndex) => {
                            let error = field?.nestedObj
                              ? errors[field?.name?.split('.')[0]]?.[
                                  field?.name?.split('.')[1]
                                ]
                                ? true
                                : false
                              : errors[field.name]
                                ? true
                                : false;
                            let errorMessage = field?.nestedObj
                              ? errors[field?.name?.split('.')[0]]?.[
                                  field?.name?.split('.')[1]
                                ]
                              : errors[field.name];
                            if (field.type === 'input') {
                              return (
                                <Grid
                                  item
                                  xs={field.xs}
                                  md={field.md}
                                  key={itemIndex}
                                >
                                  <Lable
                                    style={{ my: 2, marginRight: 'auto' }}
                                    title={field.lable}
                                  />
                                  <Controller
                                    name={field.name}
                                    control={control}
                                    render={({
                                      field: { onChange, value },
                                    }) => (
                                      <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        name={field.name}
                                        type={field.inputType}
                                        value={value}
                                        fullWidth
                                        className={classes.input}
                                        placeholder={field.placeholder}
                                        onWheel={
                                          field.inputType === 'number'
                                            ? (e) => e.target.blur()
                                            : () => {}
                                        }
                                        sx={{
                                          border: 'none',
                                        }}
                                        error={error}
                                        onChange={onChange}
                                      />
                                    )}
                                  />
                                  {error && (
                                    <ErrorField err={errorMessage?.message} />
                                  )}
                                </Grid>
                              );
                            } else if (
                              field.type === 'radio' &&
                              field.name === 'campaignType'
                            ) {
                              return (
                                <Grid
                                  item
                                  xs={field.xs}
                                  md={field.md}
                                  key={itemIndex}
                                >
                                  <Lable
                                    style={{ my: 2, marginRight: 'auto' }}
                                    title={field.label}
                                  />
                                  <Controller
                                    name={field.name}
                                    control={control}
                                    render={({
                                      field: { onChange, value },
                                    }) => (
                                      <FormControl component="fieldset">
                                        <RadioGroup
                                          row
                                          aria-label={field.name}
                                          name={field.name}
                                          value={value}
                                          onChange={onChange}
                                        >
                                          {field.options.map(
                                            (option, index) => (
                                              <FormControlLabel
                                                key={index}
                                                value={option}
                                                control={
                                                  <Radio color="primary" />
                                                }
                                                label={option}
                                              />
                                            )
                                          )}
                                        </RadioGroup>
                                      </FormControl>
                                    )}
                                  />
                                </Grid>
                              );
                            } else if (
                              field.type === 'input' ||
                              (field.type === 'radio' &&
                                field.name === 'couponGenerateType')
                            ) {
                              // Show COUPON OPTION only if Deal Pool is selected
                              if (
                                campaignDetails?.campaignType === 'Deal Pool'
                              ) {
                                return (
                                  <Grid
                                    item
                                    xs={field.xs}
                                    md={field.md}
                                    key={itemIndex}
                                  >
                                    <Lable
                                      style={{ my: 2, marginRight: 'auto' }}
                                      title={field.label}
                                    />
                                    <Controller
                                      name={field.name}
                                      control={control}
                                      render={({
                                        field: { value, onChange },
                                      }) => (
                                        <FormControl component="fieldset">
                                          <RadioGroup
                                            row
                                            aria-label={field.name}
                                            name={field.name}
                                            value={value}
                                            onChange={onChange}
                                          >
                                            {field.options.map(
                                              (option, index) => (
                                                <FormControlLabel
                                                  key={index}
                                                  value={option}
                                                  control={
                                                    <Radio color="primary" />
                                                  }
                                                  label={option}
                                                />
                                              )
                                            )}
                                          </RadioGroup>
                                        </FormControl>
                                      )}
                                    />

                                    {campaignDetails?.couponGenerateType ===
                                      'Predefined Coupon' && (
                                      <Grid item xs={12} md={10}>
                                        <Lable
                                          style={{ my: 2, marginRight: 'auto' }}
                                          title="Enter Alphanumeric Code"
                                        />
                                        <Controller
                                          name="dealPoolCouponCode"
                                          control={control}
                                          render={({
                                            field: { onChange, value },
                                          }) => (
                                            <TextField
                                              id="outlined-basic"
                                              variant="outlined"
                                              name="dealPoolCouponCode"
                                              type="text"
                                              value={value}
                                              fullWidth
                                              className={classes.input}
                                              placeholder="eg : Super200"
                                              sx={{
                                                border: 'none',
                                              }}
                                              onChange={onChange}
                                              error={errors?.dealPoolCouponCode}
                                            />
                                          )}
                                        />
                                        {errors?.dealPoolCouponCode && (
                                          <ErrorField
                                            err={
                                              errors?.dealPoolCouponCode
                                                ?.message
                                            }
                                          />
                                        )}
                                      </Grid>
                                    )}
                                  </Grid>
                                );
                              }
                            }
                            return <></>;
                          })}
                        </Grid>
                      </div>
                    );
                  } else if (!grid) {
                    return (
                      <div key={index}>
                        <Divider />
                      </div>
                    );
                  }

                  return <></>;
                })}

                <div>
                  <div>
                    <Grid container spacing={2} columns={{ xs: 4, md: 12 }}>
                      <Grid item xs={12} md={6}>
                        <div>
                          <Lable
                            style={{ my: 2, marginRight: 'auto' }}
                            title="  START DATE*"
                          />

                          <input
                            type="date"
                            value={
                              campaignDetails.startDate
                                ? moment(campaignDetails.startDate).format(
                                    'YYYY-MM-DD'
                                  )
                                : ''
                            }
                            onChange={(e) => {
                              setValue('startDate', e.target.value);
                            }}
                            min={moment().format('YYYY-MM-DD')}
                            className="border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm"
                          />

                          {/* <DatePicker
                            value={campaignDetails.startDate}
                            onChange={(newValue) => {
                              setValue('startDate', newValue);
                            }}
                            minDate={moment().toDate()}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                fullWidth
                              />
                            )}
                          /> */}
                        </div>
                        {errors?.startDate && (
                          <ErrorField err={errors?.startDate?.message} />
                        )}
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <div>
                          <Lable
                            style={{ my: 2, marginRight: 'auto' }}
                            title=" END DATE*"
                          />

                          <input
                            type="date"
                            onChange={(e) => {
                              setValue('endDate', e.target.value);
                            }}
                            min={moment(getValues('startDate'))
                              .add(7, 'days')
                              .format('YYYY-MM-DD')}
                            value={
                              campaignDetails.endDate
                                ? moment(campaignDetails.endDate).format(
                                    'YYYY-MM-DD'
                                  )
                                : ''
                            }
                            className="border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm"
                          />

                          {/* <DatePicker
                            onChange={(newValue) => {
                              setValue('endDate', newValue);
                            }}
                            mask="YYYY-MM-DD"
                            minDate={moment(campaign.startDate)
                              .add(3, 'days')
                              .toDate()}
                            value={campaignDetails.endDate}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                fullWidth
                              />
                            )}
                          /> */}
                        </div>
                        {errors?.endDate && (
                          <ErrorField err={errors?.endDate?.message} />
                        )}
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <div>
                  <div>
                    <Grid container spacing={2} columns={{ xs: 4, md: 12 }}>
                      <Grid item xs={6}>
                        <div>
                          <Lable
                            style={{ my: 2, marginRight: 'auto' }}
                            title="EXPIRY DATE OF COUPON CODE*"
                          />

                          <input
                            type="date"
                            onChange={(e) => {
                              setValue('coupons.expiryDate', e.target.value);
                            }}
                            min={moment(campaign.endDate)
                              .add(3, 'days')
                              .format('YYYY-MM-DD')}
                            value={
                              campaignDetails.coupons.expiryDate
                                ? moment(
                                    campaignDetails.coupons.expiryDate
                                  ).format('YYYY-MM-DD')
                                : ''
                            }
                            className="border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm"
                          />

                          {/* <DatePicker
                            onChange={(newValue) => {
                              setValue('coupons.expiryDate', newValue);
                            }}
                            minDate={moment(campaign.endDate)
                              .add(3, 'days')
                              .toDate()}
                            value={campaignDetails.coupons.expiryDate}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                fullWidth
                              />
                            )}
                          /> */}
                        </div>
                        {errors?.coupons?.expiryDate && (
                          <ErrorField
                            err={errors?.coupons?.expiryDate?.message}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </div>
                </div>

                <div>
                  <div>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        {/*  
                        <FileUploadHoc fileType="couponImage" set={setimageUrl}>
                          <Box
                            component="label"
                            sx={{
                              width: '90%',
                              margin: '0 auto',
                              padding: '10px',
                              background: 'white',
                              height: '100px',
                              display: 'block',
                              boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
                            }}
                            htmlFor="couponImage"
                          >
                            <Input
                              accept="image/*"
                              id="couponImage"
                              type="file"
                              className={classes.inputfile}
                            />
                            <IconButton
                              color="primary"
                              aria-label="upload picture"
                              component="span"
                              className={classes.upload}
                            >
                              <BackupIcon
                                sx={{
                                  fontSize: '40px',
                                }}
                              />

                              <Lable
                                style={{ my: 2, textAlign: 'center' }}
                                title="Upload campaign Images*"
                              />
                            </IconButton>
                          </Box>
                        </FileUploadHoc>
                        */}
                        {campaignDetails?.coupons?.imageUrl
                          ? 'Last uploaded:'
                          : ''}{' '}
                        <span>
                          {campaignDetails?.coupons?.imageUrl ? (
                            <a
                              href={campaignDetails?.coupons?.imageUrl}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Click Here
                            </a>
                          ) : (
                            ''
                          )}
                        </span>
                        {errors?.coupons?.imageUrl && (
                          <ErrorField
                            err={errors?.coupons?.imageUrl?.message}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Stack>

              {/* <div>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                  <label style={{ my: 2, marginRight: "auto", fontWeight: "bold", fontSize: "0.75rem" }}>SELECT STATE*</label>

                    <Select
                      value={selectedState}
                      onChange={handleStateChange}
                      fullWidth
                    >
                      {Object.keys(cityData).map((state, index) => (
                        <MenuItem key={index} value={state}>{state}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <label style={{ my: 2, marginRight: "auto" , fontWeight: "bold",fontSize: "0.75rem"}}>SELECT CITY*</label>
                    <Select
                      value={selectedCity}
                      onChange={handleCityChange}
                      fullWidth
                      disabled={!selectedState} // Disable city select if no state is selected
                    >
                      {selectedState && cityData[selectedState].map((city, index) => (
                        <MenuItem key={index} value={city}>{city}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              </div> */}

              {campaignDetails?.campaignType === 'Targeted Campaign' ? (
                <>
                  {campaignDetails.coupons.total <= 0 && (
                    <p className={classes.warning}>
                      Note:Please enter no of coupon to select media partners
                    </p>
                  )}
                  <p className={classes.noteLable}>
                    Note:Please select minimum three media partners for create
                    campaign
                  </p>

                  <Stack mt={1} p={2}>
                    <Box
                      sx={{
                        width: { xs: '300', sm: '500' },
                        overflowX: 'scroll',
                      }}
                    >
                      <TableSelect
                        rows={rows}
                        setSelected={setSelectedRows}
                        totalCouponCode={campaignDetails?.coupons.total > 0}
                        campaignType={campaignDetails?.campaignType}
                      />
                    </Box>

                    {errors?.mediaPartners && (
                      <ErrorField err={errors?.mediaPartners?.message} />
                    )}
                  </Stack>
                  <Stack mt={1} p={2}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={campaignDetails.isCouponChange}
                          onChange={(event) =>
                            setValue('isCouponChange', event.target.checked)
                          }
                        />
                      }
                      label="Change coupons split"
                    />
                  </Stack>

                  <div>
                    {campaignDetails.isCouponChange ? (
                      <>
                        <Grid
                          container
                          spacing={2}
                          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          className={classes.grid}
                        >
                          <Grid item xs={4}>
                            S.No
                          </Grid>
                          <Grid item xs={4}>
                            Media partners
                          </Grid>
                          <Grid item xs={4}>
                            No of coupons
                          </Grid>
                          {selectedList?.map((item, index) => {
                            return (
                              <React.Fragment key={item.id}>
                                <Grid item xs={4} sx={{ margin: '20px' }}>
                                  {index + 1}.
                                </Grid>
                                <Grid item xs={4} sx={{ margin: '20px' }}>
                                  {item?.mediaPartner.branchName}
                                </Grid>
                                <Grid item xs={4} sx={{ margin: '20px' }}>
                                  <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    type={'number'}
                                    fullWidth
                                    value={item?.noOfCoupons}
                                    className={classes.input}
                                    disabled={
                                      campaignDetails.isCouponChange
                                        ? false
                                        : true
                                    }
                                    onChange={(e) =>
                                      onChangeCoupons(
                                        e.target.value,
                                        item?.mediaPartner.id
                                      )
                                    }
                                    // InputProps={{ classes: { underline: classes.input } }}
                                    sx={{
                                      border: 'none',
                                    }}
                                  />
                                </Grid>
                              </React.Fragment>
                            );
                          })}
                        </Grid>
                        {errors?.mediaPartnersSplitCoupons && (
                          <ErrorField
                            err={errors?.mediaPartnersSplitCoupons.message}
                          />
                        )}
                        <Box sx={{ textAlign: 'center' }}>
                          <Button
                            color="success"
                            onClick={handleSubmit(() => onSubmit('Estimate'))}
                            className={classes.cancel}
                          >
                            Estimate Fee
                          </Button>
                        </Box>
                      </>
                    ) : null}
                  </div>
                  {campaignDetails.mediaPartners.length >= 3 && (
                    <Stack mt={4} p={5}>
                      <Box sx={{ textAlign: 'end' }}>
                        {`AMOUNT:${
                          estimateFeeData?.fee?.totalWithBaeonCommission
                            ? estimateFeeData?.fee?.totalWithBaeonCommission
                            : '-'
                        } `}
                      </Box>
                      <Box sx={{ textAlign: 'end' }}>
                        {`GST:${
                          estimateFeeData?.fee?.gst
                            ? estimateFeeData?.fee?.gst
                            : '-'
                        } `}
                      </Box>
                      <Box sx={{ textAlign: 'end' }}>
                        {`GRAND TOTAL:${
                          estimateFeeData?.fee?.grandTotal
                            ? estimateFeeData?.fee?.grandTotal
                            : '-'
                        }`}
                      </Box>
                    </Stack>
                  )}
                </>
              ) : (
                <>
                  <Stack mt={1} p={2}>
                    <Box
                      sx={{
                        width: { xs: '300', sm: '500' },
                        overflowX: 'scroll',
                      }}
                    >
                      <TableSelect
                        rows={rows}
                        setSelected={setSelectedRows}
                        totalCouponCode={campaignDetails?.coupons.total > 0}
                        campaignType={campaignDetails?.campaignType}
                      />
                    </Box>

                    {errors?.campaignPincodes && (
                      <ErrorField err={errors?.campaignPincodes?.message} />
                    )}
                  </Stack>
                </>
              )}

              <Box sx={{ textAlign: 'center' }}>
                <Button
                  type="submit"
                  color="success"
                  // onClick={(e) => handleSubmit(e, "add")}
                  className={classes.cancel}
                >
                  Submit
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Box style={{ textAlign: 'center', marginTop: '20px' }}>
                <Chip
                  label="No Near by stores found"
                  color="primary"
                  variant="outlined"
                />
              </Box>
            </>
          )}
        </Card>
      </form>
    </MainLayout>
  );
}

export default CreateCampaign;
