import React from 'react';
import MainLayout from '../layout/mainLayout';
import BulkInvite from '../views/admin/bulkInvite';
import Business from '../views/admin/business';
import CampaignPayment from '../views/admin/campaignPayment';
import ClaimCoupons from '../views/admin/claimCoupon';
import CreditAndRef from '../views/admin/creditandref';
import Dashboard from '../views/admin/dashboard';
import Home from '../views/admin/home';
import MyBrandPage from '../views/admin/myBrandPage';
import NearBuyStore from '../views/admin/nearBuyStores';
import CreateCampaign from '../views/admin/newCreateCampaign';
import ShareCoupons from '../views/admin/newShareCoupon';
import AddStore from '../views/admin/newStore';
import PrimeCustomer from '../views/admin/primeCustomer';
import ViewCoupon from '../views/admin/shareCoupon';
import ViewCampaigns from '../views/admin/viewCampaigns';
import ViewStore from '../views/admin/viewStores';
import BusinessDetail from '../views/auth/businessDetail';
import PrintQr from '../views/admin/viewStores/PrintQr';
import AttachQr from '../views/admin/viewStores/AttachQr';

const component = [
  { id: 1, path: '/create-store', element: <Dashboard /> },
  { id: 1.1, path: '/home', element: <Home /> },
  { id: 1.3, path: '/create-store/add-store', element: <AddStore /> },
  // { id: 1.3, path: "/create-store/add-store", element: <NewDesign /> },
  { id: 2, path: '/bulkinvite', element: <BulkInvite /> },
  { id: 3, path: '/store', element: <ViewStore /> },
  { id: 4, path: '/business', element: <Business /> },
  { id: 5, path: '/viewCampaigns', element: <ViewCampaigns /> },
  { id: 6, path: '/coupon', element: <ViewCoupon /> },
  { id: 7, path: '/createCampaign', element: <CreateCampaign /> },
  { id: 8, path: '/claimCoupons', element: <ClaimCoupons /> },
  { id: 9, path: '/shareCoupons', element: <ShareCoupons /> },
  { id: 10, path: '/campaignPayment', element: <CampaignPayment /> },
  { id: 11, path: '/credit', element: <CreditAndRef /> },
  { id: 12, path: '/primeCustomer', element: <PrimeCustomer /> },
  { id: 13, path: '/info', element: <BusinessDetail /> },
  { id: 14, path: '/brandpage', element: <MyBrandPage /> },
  {
    id: 15,
    path: '/nearby',
    element: (
      <MainLayout>
        <NearBuyStore />
      </MainLayout>
    ),
  },
  { id: 16, path: '/print-store-qr', element: <PrintQr /> },
  { id: 16, path: '/attach-qr', element: <AttachQr /> },
];

export default component;
