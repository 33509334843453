import * as yup from 'yup';

export const businessSchema = yup.object().shape({
  businessName: yup.string().required('This field is mandatory'),
  businessOwnerFirstName: yup.string().required('This field is mandatory'),
  businessOwnerLastName: yup.string().required('This field is mandatory'),
  businessOwnerEmail: yup.string().email().required('This field is mandatory'),
  referralCode: yup.string(),
  salesPersonId: yup.string(),
});

export const inputFormArray = [
  {
    lable: 'Registered Company Name',
    name: 'businessName',
    placeholder: 'Enter Registered Company Name',
  },
  {
    lable: "Business Owner's FristName",
    name: 'businessOwnerFirstName',
    placeholder: "Enter Business Owner's Frist Name",
  },
  {
    lable: "Business Owner's LastName",
    name: 'businessOwnerLastName',
    placeholder: "Enter Business Owner's Last Name",
  },
  {
    lable: 'Email ID',
    name: 'businessOwnerEmail',
    placeholder: 'Enter Email ID',
  },
  {
    lable: 'Referral Code',
    name: 'referralCode',
    placeholder: 'Apply code here',
  },
  {
    lable: 'Sale person id',
    name: 'salesPersonId',
    placeholder: 'Please enter sale person id ',
  },
];

export const initialState = {
  businessName: '',
  businessOwnerFirstName: '',
  businessOwnerLastName: '',
  businessOwnerEmail: '',
  referralCode: '',
  salesPersonId: '',
};
