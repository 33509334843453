import { v4 as uuidv4 } from 'uuid';
import BaeonnQR from './BaeonnQr';

const QRCodeGenerator = () => {
  const qrCodes = Array.from({ length: 16 }).map(
    () =>
      `${process.env.REACT_APP_API_BASE_URL}stores/qr-store-mapping/${uuidv4()}`
  );

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="container mx-auto p-4">
      <button
        onClick={handlePrint}
        className="mb-4 px-4 py-2 bg-primary text-white rounded hover:bg-blue-700 w-full sm:w-auto"
      >
        Download / Print All
      </button>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 print:grid-cols-2 print:gap-4 print:page-break-inside-avoid">
        {qrCodes.map((qrCode, index) => (
          <div
            key={index}
            className="print:w-full print:h-auto print:page-break-before-always"
            style={{
              height: '48vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }} // Center the QR code
          >
            <div
              style={{
                width: '95%',
                height: '95%',
                maxWidth: '100%',
                maxHeight: '100%',
              }}
            >
              <BaeonnQR qr={qrCode} size={200} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default QRCodeGenerator;
