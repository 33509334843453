import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const map = {
  'styled-profile': 'SPECIAL DEALS',
  'styled-dashboard': 'DEAL POOL',
};

function CardTab({ selectTab }) {
  // Step 1: State to track the selected tab
  const [selectedTab, setSelectedTab] = useState('');

  useEffect(() => {
    setSelectedTab('styled-profile');
    selectTab('SPECIAL DEALS');
  }, []);

  // Step 2: Event handler to change the selected tab
  const handleTabClick = (tabId) => {
    const tab = map[tabId];
    if (selectedTab !== tabId) {
      // Prevent unnecessary state updates
      setSelectedTab(tabId);
      selectTab(tab);
    }
  };

  // Helper function to determine button classes based on selection
  const buttonClass = (tabId) => {
    return (
      `inline-block p-4 border-b-2 rounded-t-lg w-full ${
        selectedTab === tabId ? 'text-white' : ''
      }` + (selectedTab === tabId ? ' bg-[#024f8f]' : '')
    );
  };

  return (
    <div className="max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
      <ul
        className="flex flex-wrap -mb-px text-sm font-medium text-center w-full"
        id="default-styled-tab"
        data-tabs-toggle="#default-styled-tab-content"
        data-tabs-active-classnames="text-purple-600 hover:text-purple-600 dark:text-purple-500 dark:hover:text-purple-500 border-purple-600 dark:border-purple-500"
        data-tabs-inactive-classnames="dark:border-transparent text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300"
        role="tablist"
      >
        <li className="flex-grow" role="presentation">
          <button
            className={buttonClass('styled-profile')}
            id="profile-styled-tab"
            data-tabs-target="#styled-profile"
            type="button"
            role="tab"
            aria-controls="profile"
            aria-selected={selectedTab === 'styled-profile'}
            onClick={() => handleTabClick('styled-profile')}
          >
            SPECIAL DEALS
          </button>
        </li>
        <li className="flex-grow" role="presentation">
          <button
            className={buttonClass('styled-dashboard')}
            id="dashboard-styled-tab"
            data-tabs-target="#styled-dashboard"
            type="button"
            role="tab"
            aria-controls="dashboard"
            aria-selected={selectedTab === 'styled-dashboard'}
            onClick={() => handleTabClick('styled-dashboard')}
          >
            DEAL POOL
          </button>
        </li>
      </ul>
    </div>
  );
}

CardTab.propTypes = {
  selectTab: PropTypes.func.isRequired,
};

export default CardTab;
