import { configureStore, createSlice } from '@reduxjs/toolkit';
export const login = createSlice({
  name: 'login',
  initialState: {
    mobileNumber: '',
    businessOwnerDetails: {
      businessName: '',
      businessOwnerFirstName: '',
      businessOwnerLastName: '',
      businessOwnerEmail: '',
    },
  },
  reducers: {
    loginReducer: (state, action) => {
      state.mobileNumber = action.payload.mobileNumber;
    },
    businessOwnerReducer: (state, action) => {
      state.businessOwnerDetails = {
        ...state.businessOwnerDetails,
        ...action.payload,
      };
    },
  },
});
export const slice = createSlice({
  name: 'settings',
  initialState: {
    businessDetail: {
      gst: {
        exempted: true,
        number: '',
        state: '',
        url: '',
      },
      tds: '',
    },
    bankDetail: {
      id: '',
      panNumber: '',
      accountHolderName: '',
      accountNumber: '',
      bankName: '',
      ifscCode: '',
      kycDocument: '',
    },
  },
  reducers: {
    businessDetailReducer: (state, action) => {
      state.businessDetail = {
        ...state.businessDetail,
        ...action.payload.businessDetails,
      };
    },
    updatebusinessDetailReducer: (state, action) => {
      if (action.payload.url) {
        state.businessDetail.gst.url = action.payload.url;
      } else
        state.businessDetail.gst = {
          ...state.businessDetail,
          ...action.payload,
        };
    },
    bankDetailReducer: (state, action) => {
      state.bankDetail = { ...state.bankDetail, ...action.payload };
    },
  },
});
export const credit = createSlice({
  name: 'credits',
  initialState: {
    ref: {
      credit: 0,
      referralCode: '',
    },
  },
  reducers: {
    creditReducer: (state, action) => {
      state.ref = { ...state.ref, ...action.payload };
    },
  },
});
export const storeDetail = createSlice({
  name: 'store',
  initialState: {
    storeDetail: {
      branchName: '',
      category: '',
      locality: '',
      address: { street: '', state: '', city: '', zipcode: '' },
      email: '',
      contactNumber: '',
      customerCareNumber: '',
      numberOfWalkIns: 0,
      averageBillValue: 0,
      imageUrl: '',
      logoUrl: '',
      workingHours: [],
      id: '',
      type: 'chain',
    },
  },
  stores: [],
  invites: [],
  reducers: {
    storeReducer: (state, action) => {
      state.storeDetail = { ...state.storeDetail, ...action.payload };
    },
    multiplestoresReducer: (state, action) => {
      state.stores = [...action.payload];
    },
    invitesStoresReducer: (state, action) => {
      state.invites = [...action.payload];
    },
  },
});
export const userDetail = createSlice({
  name: 'user',
  initialState: {
    userDetail: {},
  },
  reducers: {
    userReducer: (state, action) => {
      state.userDetail = { ...action.payload };
    },
  },
});
export const campaignDetails = createSlice({
  name: 'campaign',
  initialState: {
    viewCampaign: [],
  },
  reducers: {
    viewCampaignReducer: (state, action) => {
      state.viewCampaign = [...action.payload];
    },
  },
});
export const couponDetails = createSlice({
  name: 'coupon',
  initialState: {
    viewCoupon: [],
    couponList: [],
  },
  reducers: {
    viewCouponReducer: (state, action) => {
      state.viewCoupon = [...action.payload];
    },
    couponListReducer: (state, action) => {
      state.couponList = [...action.payload];
    },
  },
});
export const campaignAdd = createSlice({
  name: 'campaignAdd',
  initialState: {
    addCampaign: {
      storeId: '',
      title: '',
      description: '',
      coupons: {
        total: 0,
        imageUrl: '',
        expiryDate: '',
      },
      applicationLinks: {
        mobile: '',
        website: '',
      },
      extraText: '',
      startDate: '',
      endDate: '',
      mediaPartners: [],
      creditPayment: false,
      cashPayment: true,
    },
    mediaPartner: [],
  },
  reducers: {
    addCampaignReducer: (state, action) => {
      state.addCampaign = { ...state.addCampaign, ...action.payload };
    },
    updateCouponReducer: (state, action) => {
      state.addCampaign.coupons = {
        ...state.addCampaign.coupons,
        ...action.payload,
      };
    },
    mediaPartnerReducer: (state, action) => {
      state.mediaPartner = [...action.payload];
    },
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export const paymentDetail = createSlice({
  name: 'paymentDetails',
  initialState: {
    paymentDetail: {},
  },
  reducers: {
    addPaymentReducer: (state, action) => {
      state.paymentDetail = { ...state.paymentDetail, ...action.payload };
    },
  },
});

export const {
  businessDetailReducer,
  bankDetailReducer,
  updatebusinessDetailReducer,
} = slice.actions;
export const { creditReducer } = credit.actions;
export const { storeReducer, multiplestoresReducer, invitesStoresReducer } =
  storeDetail.actions;
export const { viewCampaignReducer } = campaignDetails.actions;
export const { viewCouponReducer, couponListReducer } = couponDetails.actions;
export const { addCampaignReducer, mediaPartnerReducer, updateCouponReducer } =
  campaignAdd.actions;
export const { addPaymentReducer } = paymentDetail.actions;
export const { loginReducer, businessOwnerReducer } = login.actions;
export const { userReducer } = userDetail.actions;

export const store = configureStore({
  reducer: {
    settings: slice.reducer,
    credit: credit.reducer,
    storeDetail: storeDetail.reducer,
    campaignDetails: campaignDetails.reducer,
    campaignAdd: campaignAdd.reducer,
    paymentDetail: paymentDetail.reducer,
    loginDetail: login.reducer,
    couponDetails: couponDetails.reducer,
    userDetail: userDetail.reducer,
  },
});

// store.subscribe(() => console.log(store.getState()+"state"))
