import { useState } from 'react';

import { makeStyles } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';

import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  actionBtnpay: {
    background: '#377805',
    color: '#ffff',
  },
  actionBtnPaid: {
    background: '#1eb1e3',
    color: '#ffff',
  },
  logoImage: {
    width: '100px',
    height: '100px',
    objectFit: 'cover',
  },
}));
export default function TableSelect({
  setSelected,
  setShowModal,
  rows,
  campaignType,
}) {
  const classes = useStyles();
  const [selectedRows, setSelectedRows] = useState([]);
  const [lengthRows, setLengthRows] = useState([]);

  const columns = [
    // {
    //   headerName: "Sr.No.",
    //   headerAlign: "center",
    //   align: "center",
    //   width: 100,

    //   renderCell: (params) => (
    //     console.log({params}+"check")
    //     // <Link href={`/form/${params.value}`}>{params.value}</Link>
    //     // <img src={params.value} className="w-20 h-20" />
    //   ),
    // },
    {
      field: 'logo',
      headerName: 'Logo',
      width: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <img
          src={params.value}
          className={classes.logoImage}
          alt={params.value}
        />
      ),
    },
    {
      field: 'mediapartner',
      headerName: 'Media Partner',
      width: 170,
      editable: true,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'domain',
      headerName: 'Domain',
      width: 150,
      editable: true,
      align: 'center',
      headerAlign: 'center',
    },
    // {
    //   field: "distance",
    //   headerName: "Distance(Kms.)",
    //   type: "number",
    //   width: 150,
    //   editable: true,
    //   align: "center",
    //   headerAlign: "center",
    // },

    {
      field: 'converstionrate',
      headerName: 'Conversion %',
      type: 'number',
      width: 200,
      editable: true,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'sharingrate',
      headerName: 'Sharing %',
      type: 'number',
      width: 200,
      editable: true,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'fee',
      headerName: 'Fee',
      type: 'number',
      width: 110,
      editable: true,
      align: 'center',
      headerAlign: 'center',
    },
  ];
  const dealPoolColumn = [
    {
      field: 'state',
      headerName: 'State',
      width: 150,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'city',
      headerName: 'City',
      width: 150,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'pincode',
      headerName: 'Pincode',
      width: 150,
      headerAlign: 'center',
      align: 'center',
    },
  ];

  const row = [
    {
      id: 1,
      domain: 'Pharma',
      distance: 32,
      mediapartner: 'Johnson',
      converstionrate: 12.3,
      sharingrate: 12.3,
      fee: 35,
    },
    {
      id: 2,
      domain: 'Grocery',
      distance: 20,
      mediapartner: 'Mercedes',
      converstionrate: 12.3,
      sharingrate: 12.3,
      fee: 35,
    },
    {
      id: 3,
      domain: 'Jewelry',
      distance: 15,
      mediapartner: 'Appollo',
      converstionrate: 12.3,
      sharingrate: 12.3,
      fee: 35,
    },
    {
      id: 4,
      domain: 'Food',
      distance: 33,
      mediapartner: 'Cadbury',
      converstionrate: 12.3,
      sharingrate: 12.3,
      fee: 35,
    },
    {
      id: 5,
      domain: 'Gaming',
      distance: 78,
      mediapartner: 'Mercury',
      converstionrate: 12.3,
      sharingrate: 12.3,
      fee: 35,
    },
    {
      id: 6,
      domain: 'Pharma',
      distance: 45,
      mediapartner: 'Ninad',
      converstionrate: 12.3,
      sharingrate: 12.3,
      fee: 35,
    },
    {
      id: 7,
      domain: 'Pharma',
      distance: 2,
      mediapartner: 'DHAN',
      converstionrate: 12.3,
      sharingrate: 12.3,
      fee: 35,
    },
  ];

  const onSelect = () => {
    setShowModal(true);
  };
  const dispatch = useDispatch();

  return (
    <div style={{ height: 800, background: 'white' }}>
      <DataGrid
        rows={rows}
        rowHeight={100}
        columns={
          campaignType === 'Targeted Campaign' ? columns : dealPoolColumn
        }
        pageSize={7}
        checkboxSelection
        // checkboxSelection={totalCouponCode?true:false}
        disableSelectAllCheckbox
        onSelectionModelChange={(newSelection) => {
          setSelected(newSelection, true);
        }}
      />
    </div>
  );
}
