import { Box, Button, Chip, Grid, makeStyles } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import moment from 'moment';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/baeon-full.jpg';
import { getCurrentUser } from '../../service_config/auth.service';
import { truncateString } from '../../utilities/commonFunction';
import { BUSINESSOWNER } from '../../utilities/constants';
import WrapperCard from '../wrapperCard/wrapperCard';

const useStyles = makeStyles((theme) => ({
  actionBtnpay: {
    background: '#f04141',
    color: '#ffff',
  },
  actionviewBtn: {
    background: '#218f17',
    color: '#ffff',
  },
  actionBtnPaid: {
    background: '#218f17',
    color: '#ffff',
  },
  storeImg: {
    width: '80px',
    height: '80px',
    borderRadius: '50%',
  },
  storeLeft: {
    width: '80px',
    height: '80px',
    borderRadius: '10px',
  },
  card: {
    background: '#3f51b5',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleCoupons: {
    fontSize: '3rem',
    fontWeight: '700',
  },
  textColor: {
    color: '#FFFFFF',
  },
  description: {
    color: '#FFFFFF',
    maxHeight: '200px',
    overflowY: 'hidden',
  },
  textdecoration: {
    textDecoration: 'underline',
  },
}));
export default function CardShareCoupons({
  setSelected,
  setShowModal,
  rows,
  action,
}) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [description, setDescription] = useState('');

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = (des) => {
    setDescription(des);
    setOpen(true);
  };
  const onPay = (campaignId, coupons) => {
    navigate(`/campaignPayment`, {
      state: { campaignId: campaignId, couponsCount: coupons },
    });
  };
  const columns = [
    {
      field: 'sno',
      headerName: 'SNo',
      headerAlign: 'center',
      align: 'center',
      width: 100,
      hidden: true,
    },
    {
      field: 'created',
      headerName: 'created',
      width: 150,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 150,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 400,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'numberOfCoupons',
      headerName: 'No Of Coupons',
      type: 'number',
      width: 120,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'shared',
      headerName: 'Shared',
      type: 'number',
      width: 120,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'notshared',
      headerName: 'Not shared',
      type: 'number',
      width: 120,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'paymentstatus',
      headerName: 'Payment Status',
      width: 150,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div>
            {params.row.payment.status === 'pending' ? (
              <Button
                color="success"
                size="small"
                className={classes.actionBtnpay}
                onClick={() => onPay(params)}
              >
                pay
              </Button>
            ) : (
              <Button
                color="success"
                size="small"
                className={classes.actionBtnPaid}
              >
                paid
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  const onSelect = (data) => {
    setSelected({ storeId: data?.mediaPartner?.id, campaignId: data?.id });
    setShowModal(true);
  };

  return (
    <div style={{ minHeight: 800, background: 'white', padding: '10px' }}>
      <Box component={'h2'} sx={{ textAlign: 'center' }}>
        {' '}
        {action === 'pay' ? 'View Campaigns' : 'share coupon'}
      </Box>
      <Grid container spacing={2} columns={{ xs: 2, md: 12 }}>
        {rows?.length > 0 ? (
          rows.map((item) => {
            return (
              <>
                <Grid item xs={12} md={6}>
                  <WrapperCard bg="rgba(2, 132, 199,1)" borderColor="#ffb338">
                    <Box className={classes.imageContainer}>
                      <img
                        className={classes.storeLeft}
                        src={item?.imageUrl ? item?.imageUrl : Logo}
                        alt="logo"
                      />
                      <img
                        className={classes.storeImg}
                        src={item?.imageUrl ? item?.imageUrl : Logo}
                        alt="logocenter"
                      />
                      <img
                        className={classes.storeLeft}
                        src={item?.imageUrl ? item?.imageUrl : Logo}
                        alt="logoapp"
                      />
                    </Box>
                    <Box sx={{ textAlign: 'center', marginTop: '50px' }}>
                      <strong
                        className={`${classes.titleCoupons} ${classes.textColor}`}
                      >
                        {item?.title}
                      </strong>
                    </Box>

                    <Box sx={{ textAlign: 'center', marginTop: '5px' }}>
                      <span className={classes.textColor}>Description:</span>
                      <p className={`${classes.description} `}>
                        {truncateString(item?.description, 120)}
                        {item?.description?.length > 120 ? (
                          <span
                            onClick={
                              item?.description?.length > 120
                                ? () => {
                                    handleOpen(item?.description);
                                  }
                                : () => {}
                            }
                            className={classes.textColor}
                          >
                            view more
                          </span>
                        ) : (
                          ''
                        )}
                      </p>
                    </Box>
                    <Box sx={{ textAlign: 'center', marginTop: '5px' }}>
                      <span className={classes.textColor}>Location:</span>
                      <p className={`${classes.textColor} `}>
                        {`${item?.address?.city},${item?.address?.state}-${item?.address?.zipcode}`}
                      </p>
                    </Box>
                    <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
                      <span className={classes.textColor}>Valid until:</span>{' '}
                      <span className={classes.textColor}>
                        {moment(item?.endDate).format('DD/MM/YYYY')}
                      </span>
                    </Box>

                    <Box sx={{ textAlign: 'center', marginTop: '50px' }}>
                      <Grid container spacing={2} columns={{ xs: 2, md: 12 }}>
                        <Grid item xs={12} md={6} className={classes.textColor}>
                          Shared:{' '}
                          <strong>
                            {action === 'pay'
                              ? item?.coupons?.shared
                              : item?.mediaPartner?.coupons?.shared}{' '}
                            Out of{' '}
                            {action === 'pay'
                              ? item?.coupons?.total
                              : item?.mediaPartner?.coupons?.assigned
                                ? item?.mediaPartner?.coupons?.assigned
                                : item?.coupons?.total}
                          </strong>
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.textColor}>
                          Claimed:{' '}
                          <strong>
                            {action === 'pay'
                              ? item?.coupons?.claimed
                              : item?.mediaPartner?.coupons?.claimed}{' '}
                            Out of shared{' '}
                            {action === 'pay'
                              ? item?.coupons?.shared
                              : item?.mediaPartner?.coupons?.shared}
                          </strong>
                        </Grid>
                      </Grid>
                    </Box>

                    {getCurrentUser().role.name === BUSINESSOWNER && (
                      <Box sx={{ textAlign: 'center', marginTop: '50px' }}>
                        {action === 'share' ? (
                          <Button
                            color="success"
                            size="small"
                            onClick={() => onSelect(item)}
                            className={classes.actionBtnPaid}
                          >
                            Share
                          </Button>
                        ) : null}
                        {action === 'dealPool' ? null : action === 'pay' ? (
                          <>
                            {item?.payment?.status === 'pending' ? (
                              <Button
                                color="success"
                                size="small"
                                className={classes.actionBtnpay}
                                onClick={() =>
                                  onPay(item?.id, item?.coupons?.total)
                                }
                              >
                                pay
                              </Button>
                            ) : (
                              <Button
                                color="success"
                                size="small"
                                className={classes.actionBtnPaid}
                              >
                                paid
                              </Button>
                            )}
                          </>
                        ) : null}
                      </Box>
                    )}
                  </WrapperCard>
                </Grid>
              </>
            );
          })
        ) : (
          <>
            <Box
              style={{ textAlign: 'center', marginTop: '20px', width: '100%' }}
            >
              <Chip label="No data found" color="primary" variant="outlined" />
            </Box>
          </>
        )}
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Campaign Description</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
