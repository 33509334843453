import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  getBankDetail,
  submitBankDetail,
} from '../../service_config/admin.service';
import ErrorField from '../errorField';
import FileUploadHoc from '../File.Upload';
import { bankInitialState, bankSchema } from './validation';

import {
  Box,
  Grid,
  Input,
  TextField,
  createTheme,
  makeStyles,
} from '@material-ui/core';
import BackupIcon from '@mui/icons-material/Backup';
import Lable from '../Typography/lable';

const theme = createTheme({
  overrides: {
    MuiInput: {
      underline: {
        '&:hover:not($disabled):before': {
          backgroundColor: 'rgba(0, 188, 212, 0.7)',
        },
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  lableTitle: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontWeight: 'bold',
    color: '#57748a',
  },
  lable: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontWeight: 'bold',
    color: '#57748a',
  },
  cards: {
    marginTop: '100px',
  },

  card: {
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
    borderRadius: '5px',
    padding: '20px',
  },

  inputfile: {
    display: 'none',
  },
  upload: {
    background: '#f0f3f5',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  radio: {
    '&$checked': {
      color: '#4B8DF8',
    },
  },
  checked: {},
}));

export default function CardBankDetails() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { ...bankInitialState },
    resolver: yupResolver(bankSchema),
    mode: 'onBlur',
  });
  const bankDetail = watch();
  const setimageUrl = (data) => {
    setValue('kycDocument', data);
    setError('kycDocument', { type: 'custom', message: '' });
  };
  const getBankDetailData = async () => {
    const response = await getBankDetail();
    if (!response.error) {
      reset({ ...response.result });
    }
  };
  React.useEffect(() => {
    getBankDetailData();
  }, []);

  const handleSubmitForm = async (data) => {
    const res = await submitBankDetail({ ...data });
    if (!res.error) {
      toast.success(res.msg);
    } else {
      toast.error(res.msg);
    }
  };

  const inputArray = [
    {
      lable: 'ACCOUNT NUMBER*',
      name: 'accountNumber',
      placeholder: 'ENTER ACCOUNT NUMBER',
      type: 'number',
      gridSpacing: 6,
    },
    {
      lable: 'PAN NUMBER*',
      name: 'panNumber',
      placeholder: 'ENTER PAN NUMBER',
      type: 'text',
      gridSpacing: 6,
    },

    {
      lable: 'ACCOUNT HOLDER*',
      name: 'accountHolderName',
      placeholder: 'ENTER  NAME',
      type: 'text',
      gridSpacing: 12,
    },
    {
      lable: 'BANK NAME*',
      name: 'bankName',
      placeholder: 'ENTER BANK NAME',
      type: 'text',
      gridSpacing: 6,
    },
    {
      lable: 'IFSC CODE*',
      name: 'ifscCode',
      placeholder: 'ENTER IFSC CODE',
      type: 'text',
      gridSpacing: 6,
    },
  ];
  const onSubmit = (data) => {
    handleSubmitForm(data);
  };

  return (
    <>
      <Card>
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              className={classes.grid}
            >
              <Grid item xs={6}>
                <Typography
                  variant="h6"
                  display="block"
                  sx={{
                    lineHeight: '1rem',
                    fontWeight: 'bold',
                    color: '#1c1c1c',
                  }}
                  className={classes.lable}
                >
                  Bank Details
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ textAlign: 'end' }}>
                  <Button size="small" type="submit">
                    update
                  </Button>
                </Box>
              </Grid>
            </Grid>{' '}
            <Box p={2}>
              <Typography
                variant="p"
                display="block"
                className={classes.lableTitle}
              >
                ACCOUNT DETAILS
              </Typography>
              <Box p={2}>
                <Grid container spacing={2}>
                  {inputArray.map((input) => {
                    return (
                      <>
                        <Grid item xs={input.gridSpacing}>
                          <Lable
                            style={{ my: 1, display: 'block' }}
                            title={input.lable}
                          />
                          <Controller
                            name={input.name}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <TextField
                                variant="outlined"
                                placeholder={input.placeholder}
                                name={input.name}
                                fullWidth
                                value={value}
                                onChange={onChange}
                                error={errors[input.name] ? true : false}
                                type={input.type}
                              />
                            )}
                          />
                          {errors[input?.name] && (
                            <ErrorField err={errors[input.name]?.message} />
                          )}
                        </Grid>
                      </>
                    );
                  })}

                  {/* <Grid item xs={6}>
                  <div>
                 
                    <Lable
                  style={{ my: 2, marginRight: "auto" }}
                  title="ACCOUNT NUMBER"
                />
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      fullWidth
                      type="number"
                      value={bankDetail.accountNumber}

                      sx={{
                        border: "none",
                      }}
                      onChange={(e) =>
                        dispatch(
                          bankDetailReducer({
                            accountNumber: e.target.value,
                          })
                        )
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div>
                 
                    <Lable
                  style={{ my: 2, marginRight: "auto" }}
                  title=" PAN NUMBER"
                />
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      fullWidth
                      type="number"
                      value={bankDetail.panNumber}

                      sx={{
                        border: "none",
                      }}
                      onChange={(e) =>
                        dispatch(
                          bankDetailReducer({
                            panNumber: e.target.value,
                          })
                        )
                      }
                    />
                  </div>
                </Grid> */}
                </Grid>

                {/* <Lable
                  style={{ my: 2, marginRight: "auto" }}
                  title=" ACCOUNT HOLDER"
                />
              <TextField
                id="outlined-basic"
                variant="outlined"
                fullWidth
                sx={{
                  border: "none",
                }}
                value={bankDetail.accountHolderName}

                onChange={(e) =>
                  dispatch(
                    bankDetailReducer({
                      accountHolderName: e.target.value,
                    })
                  )
                }
              /> */}
                {/* <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div>
                   
                    <Lable
                  style={{ my: 2, marginRight: "auto" }}
                  title="BANK NAME"
                />
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      fullWidth
                      value={bankDetail.bankName}

                      sx={{
                        border: "none",
                      }}
                      onChange={(e) =>
                        dispatch(
                          bankDetailReducer({
                            bankName: e.target.value,
                          })
                        )
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div>
                  
                    <Lable
                  style={{ my: 2, marginRight: "auto" }}
                  title="IFSC CODE"
                />
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      fullWidth
                      sx={{
                        border: "none",
                      }}
                      value={bankDetail.ifscCode}

                      onChange={(e) =>
                        dispatch(
                          bankDetailReducer({
                            ifscCode: e.target.value,
                          })
                        )
                      }
                      
                    />
                  </div>
                </Grid>
              </Grid> */}
                <FileUploadHoc fileType="bankStatement" set={setimageUrl}>
                  <Box
                    component="label"
                    sx={{
                      width: '90%',
                      margin: '0 auto',
                      padding: '10px',
                      background: 'white',
                      height: '100px',
                      boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    htmlFor="bankStatement"
                    mt={2}
                  >
                    <BackupIcon
                      sx={{
                        fontSize: '40px',
                        color: 'blue',
                      }}
                    />

                    <Lable
                      style={{ my: 2, textAlign: 'center' }}
                      title=" Upload KYC Details"
                    />
                    <Input
                      accept="image/*"
                      id="bankStatement"
                      type="file"
                      className={classes.inputfile}
                    />
                  </Box>
                </FileUploadHoc>
                <Box sx={{ paddingTop: '10px' }}>
                  {' '}
                  {bankDetail.kycDocument ? 'Last Uploaded:' : ''}{' '}
                  {bankDetail.kycDocument ? (
                    <a
                      href={bankDetail.kycDocument}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Click here
                    </a>
                  ) : (
                    ''
                  )}
                </Box>

                <Lable
                  style={{ my: 2, marginRight: 'auto' }}
                  title=" KYC Details include Cancelled Cheque Image or Bank Statement"
                />
              </Box>
              {errors['kycDocument'] && (
                <ErrorField err={errors['kycDocument']?.message} />
              )}
            </Box>
          </form>
        </CardContent>
      </Card>
    </>
  );
}
