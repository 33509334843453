import Typography from '@mui/material/Typography';
import React from 'react';

export default function Lable(props) {
  return (
    <Typography
      sx={{
        fontSize: '0.75rem',
        lineHeight: '1rem',
        fontWeight: '700',
        color: '#1c1c1c',
        ...props.style,
      }}
      variant="lable"
      color="text.secondary"
    >
      {props?.title?.toUpperCase()}
    </Typography>
  );
}
