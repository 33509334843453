import { Link } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function TermsAndConditions() {
  const navigate = useNavigate();

  return (
    <>
      <div>
        <p>
          Baeonn Breach Terms:
          <Link
            target="_blank"
            href="https://s3.ap-south-1.amazonaws.com/baeonn.com-terms-and-conditions/BreachTerms.pdf"
          >
            Click Here
          </Link>
        </p>
        <p>
          Baeonn Membership Agreement:{' '}
          <Link
            target="_blank"
            href="https://s3.ap-south-1.amazonaws.com/baeonn.com-terms-and-conditions/MembershipAgreement.pdf"
          >
            Click Here
          </Link>
        </p>
        <p>
          Baeonn Payment and Transaction Service Terms:{' '}
          <Link
            target="_blank"
            href="https://s3.ap-south-1.amazonaws.com/baeonn.com-terms-and-conditions/PaymentandTransactionServiceTerms.pdf"
          >
            Click Here
          </Link>
        </p>
        <p>
          Baeonn Privacy Policy BAEON:{' '}
          <Link
            target="_blank"
            href="https://s3.ap-south-1.amazonaws.com/baeonn.com-terms-and-conditions/PrivacyPolicyBAEON.pdf"
          >
            Click Here
          </Link>
        </p>
      </div>
    </>
  );
}
