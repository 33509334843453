import { Box, Button, CardActionArea, CardActions } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import QRCode from 'qrcode.react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';

import { TextField } from '@material-ui/core';
import MuiPhoneNumber from 'material-ui-phone-number';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import storeImage from '../../assets/store.jpg';
import {
  getCampaingnByQR,
  updateCouponQr,
} from '../../service_config/admin.service';
export default function QRCampaign() {
  const [campaignData, setCampaignData] = useState();
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [couponDetails, setCouponDetails] = useState({});
  const { storeId } = useParams();
  const [showCliamed, setShowCliamed] = useState(false);

  const salespersonId = 'SP12345';
  const url = 'https://www.google.com';
  const additionalDetails = 'Campaign=Summer2024';

  const getDataByQr = async () => {
    const response = await getCampaingnByQR({ storeId });

    if (!response.error) {
      setCampaignData(response.result);
    }
  };
  const getSessionData = () => {
    if (sessionStorage.getItem('coupon') === 'claimed') {
      setShowCliamed(true);
    }
  };
  useEffect(() => {
    getDataByQr();
    getSessionData();
    generateQrCode(salespersonId, url, additionalDetails);
  }, []);

  const generateQrCode = (salespersonId, url, additionalDetails) => {
    const data = `SPID:${salespersonId};URL:${url};DETAILS:${additionalDetails}`;
    // Here you can handle the data and use it as needed for the QR code generation
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!phone && !name) {
      toast.error(' Name and phone number are  required');
      return;
    }
    setLoading(true);

    const payLoad = {
      advertiser: couponDetails?.advertiser,
      campaignId: couponDetails?.campaignId,
      mobileNumber: phone,
      sharedByStoreId: storeId,
      name: name,
      storeBusinessId: couponDetails?.storeBusinessId,
    };

    const res = await updateCouponQr({ payLoad });
    if (!res.error) {
      setLoading(false);
      setOpenModal(false);
      toast.success('Coupons shared sucessfully');
      sessionStorage.setItem('coupon', 'claimed');
      getSessionData();
    } else {
      setOpenModal(false);
      setLoading(false);
    }
  };
  function handleOnChange(value) {
    const number = `${value.replace(/[^\d\+]/g, '')}`;
    setPhone(number);
  }
  const handleOpenAndClose = (item) => {
    setOpenModal(!openModal);
    setCouponDetails({
      advertiser: {
        storeId: item.campaignStoreId,
        businessId: item.campaignBusinessId,
      },
      campaignId: item.campaignId,
      storeBusinessId: item.storeBusinessId,
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: '#014f8e',
        minHeight: '100vh',
      }}
    >
      <Paper elevation={3}>
        <Typography variant="h2" component="div" sx={{ padding: '10px' }}>
          Coupons
        </Typography>
      </Paper>

      {!showCliamed ? (
        <>
          <Grid container spacing={2} sx={{ padding: '50px' }}>
            {campaignData?.map((data) => {
              return (
                <Grid item xs={8} md={4} key={data?.campaignId}>
                  <Card sx={{ maxWidth: 345 }}>
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        height="200"
                        image={storeImage}
                        alt="store image "
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {data.campaignstoreName}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    <CardActions>
                      <Button
                        size="small"
                        color="primary"
                        onClick={() => handleOpenAndClose(data)}
                      >
                        Click here to Get coupon
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
          </Grid>

          {campaignData?.length === 0 ? (
            <Paper elevation={3}>
              <Typography variant="h5" component="div" sx={{ padding: '10px' }}>
                No coupons available for this store
              </Typography>
            </Paper>
          ) : null}
        </>
      ) : (
        <Paper>
          <Typography variant="h5" component="div" sx={{ padding: '10px' }}>
            Your coupon has been shared sucessfully
          </Typography>
        </Paper>
      )}
      <Dialog open={openModal} maxWidth="900px">
        <form onSubmit={handleSubmit}>
          <DialogTitle>Share</DialogTitle>

          <DialogContent
            sx={{ textAlign: 'center', maxWidth: '500px', margin: '0 auto' }}
          >
            <Typography variant="h5" display="block">
              Thanks for buying
            </Typography>

            <Grid
              container
              alignContent="center"
              justifyContent="center"
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item sm={12} md={12}></Grid>
              <Grid item sm={12} md={12}>
                <Typography
                  variant="overline"
                  display="block"
                  sx={{
                    fontSize: '12px',
                    lineHeight: '1rem',
                    fontWeight: 'bold',
                    color: '#1c1c1c',
                    marginBottom: '10px',
                  }}
                >
                  Name
                </Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  sx={{
                    border: 'none',
                  }}
                  // disabled={emailFocus?emailFocus:false}
                />
              </Grid>

              <Grid item sm={12} md={8} justifyContent={'center'}>
                <Typography
                  variant="overline"
                  display="block"
                  sx={{
                    fontSize: '12px',
                    lineHeight: '1rem',
                    fontWeight: 'bold',
                    color: '#1c1c1c',
                    marginBottom: '10px',
                  }}
                >
                  Phone number
                </Typography>

                <MuiPhoneNumber
                  name="phone"
                  id="phone"
                  placeholder="Enter"
                  // data-cy="user-phone"
                  defaultCountry={'in'}
                  fullWidth
                  variant="outlined"
                  sx={{ mb: 4 }}
                  onChange={handleOnChange}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleOpenAndClose}>close</Button>
            <Button type="submit" disabled={loading}>
              Share
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Box sx={{ textAlign: 'center', padding: '20px' }}>
        <QRCode
          value={`SPID:${salespersonId};URL:${url};DETAILS:${additionalDetails}`}
          size={256}
          bgColor="#ffffff"
          fgColor="#000000"
          level="H"
          includeMargin={true}
        />
      </Box>
    </Box>
  );
}
