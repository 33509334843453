import React, { useEffect, useState } from 'react';
import MainLayout from '../../layout/mainLayout';

import { Box, Grid, TextField, Typography } from '@material-ui/core';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MuiPhoneNumber from 'material-ui-phone-number';
import { useSelector } from 'react-redux';
import {
  getShareCouponList,
  onGenerateCoupons,
  shareCoupon,
} from '../../service_config/admin.service';

import { makeStyles } from '@material-ui/core';
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useNavigate,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  getShareCouponListDealPool,
  getStoreDetailByPhoneNumber,
} from '../../service_config/admin.service';
import BulkInviteReplica from './newBulkInvite';

import UserCoupons from './CouponShare';

const useStyles = makeStyles((theme) => ({
  cards: {
    marginTop: '100px',
  },
  submitBtn: {
    background: 'blue !important',
    color: 'white  !important',
    fontWeight: '700  !important',
    '&:hover': {
      background: 'blue  !important',
      color: 'white  !important',
    },
  },
  paper: { width: '900px' },
}));

export default function ShareCoupons() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [openModal, setOpenModal] = React.useState(false);
  const [renderRouter, setRenderRouter] = React.useState(false);

  const handleClickOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const [maxWidth, setMaxWidth] = React.useState('lg');
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [name, setName] = React.useState('');
  const [phoneFocus, setPhoneFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [couponDetails, setCouponDetails] = React.useState({});

  const [dealPoolCampaigns, setDealPoolCampaigns] = useState([]);
  const [storeId, setStoreId] = useState('');
  const [rows, setRows] = React.useState([]);
  const [stores, setStores] = React.useState([]);

  const [value, setValue] = React.useState('1');

  const getDealPoolCampaign = async (storeId) => {
    const campaigns = await getShareCouponListDealPool(storeId);
    if (!campaigns?.error) {
      setDealPoolCampaigns(campaigns?.result);
    }
  };

  const getStoredetails = async () => {
    const storesdetails = await getStoreDetailByPhoneNumber();
    if (!storesdetails?.error) {
      setStores(storesdetails?.result);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const onSelectStoreId = (e) => {
    setStoreId(e.target.value);
  };
  useEffect(() => {
    getStoredetails();
  }, []);
  useEffect(() => {
    if (stores?.length > 0) {
      setStoreId(stores?.[0]?.id);
    }
  }, [stores]);
  useEffect(() => {
    if (storeId) {
      getDealPoolCampaign(storeId);
    }
  }, [storeId]);

  const handlePersonalMessageChange = (event) => {
    if (event.target.value.length <= 100) {
      setName(event.target.value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email && !phone) {
      toast.error('Any one email or phone is required');
      return;
    }

    const res = await shareCoupon({
      id: couponDetails?.id,
      data: {
        name: name,
        email: email,
        mobileNumber: phone,
      },
    });
    if (!res.error) {
      handleClose();
      toast.success(res.msg);
      setPhone('');
      setEmail('');
    } else {
      toast.error(res.msg);
      setPhone('');
      setEmail('');
    }
  };
  const focusChange = (type) => {
    switch (type) {
      case 'email':
        setPhoneFocus(true);
        setEmailFocus(false);
        break;
      case 'phone':
        setPhoneFocus(false);
        setEmailFocus(true);
        break;

      default:
        break;
    }
  };
  function handleOnChange(value) {
    const number = `${value.replace(/[^\d\+]/g, '')}`;
    setPhone(number);
  }

  const onShareCoupons = async (data = {}) => {
    let payload = {
      campaignId: data?.campaignId,
      storeId: data?.storeId ? data?.storeId : storeId,
    };
    const res = await onGenerateCoupons(payload);
    if (!res.error) {
      setCouponDetails(res.data.result);
      handleClickOpen();
    } else {
      toast.error(res.msg);
    }
  };

  const listShareCoupon = useSelector(
    (state) => state.couponDetails.couponList
  );
  React.useEffect(() => {
    getShareCouponList();
  }, []);

  return (
    <>
      <MainLayout>
        {DropDown()}
        <br />
        <UserCoupons storeId={storeId} />
        {/* <Card variant="outlined" className={classes.card}>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  <Tab label="Deal pool" value="1" />
                  <Tab label="Target Campaigns" value="2" />


                </TabList>
              </Box>
              <TabPanel value="1">
                <>
                  <Typography
                    variant="h6"
                    display="block"
                    sx={{
                      // fontSize: "12px",
                      lineHeight: "1rem",
                      fontWeight: "bold",
                      color: "#1c1c1c",
                    }}
                    className={classes.lable}
                  >
                    Deal pool Campaigns
                  </Typography>

                  <Stack>
                    <Lable
                      style={{ my: 2, marginRight: "auto" }}
                      title="SELECT STORE VIEW CAMPAIGN"
                    />

                    <Select
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      fullWidth
                      variant="outlined"
                      value={storeId}
                      onChange={onSelectStoreId}
                    >
                      <MenuItem value="">
                        <em>Select Store</em>
                      </MenuItem>
                      {stores?.map((store) => (
                        <MenuItem value={store?.id}>{store?.branchName}</MenuItem>
                      ))}
                    </Select>{" "}

                  </Stack>
                  {dealPoolCampaigns?.length > 0 ?
                    <div className={classes.cards} >
                      <CardShareCoupons rows={dealPoolCampaigns} action="share" setSelected={onShareCoupons} closeModal={handleClose} />
                    </div> : <>
                      <Box style={{ textAlign: "center", marginTop: "20px", width: "100%" }}>
                        <Chip label="No data found" color="primary" variant="outlined" />
                      </Box>
                    </>}
                </>
              </TabPanel>
              <TabPanel label="Deal pool" value="2">
                <>
                  <Typography
                    variant="h6"
                    display="block"
                    sx={{
                      // fontSize: "12px",
                      lineHeight: "1rem",
                      fontWeight: "bold",
                      color: "#1c1c1c",
                    }}
                    className={classes.lable}
                  >
                    Targeted Campaigns
                  </Typography>

                  {listShareCoupon?.length > 0 ?
                    <div className={classes.cards}>
                      <CardShareCoupons
                        closeModal={handleClose}
                        rows={listShareCoupon}
                        setSelected={onShareCoupons}
                        action="share"
                      />

                    </div>

                    : <>
                      <Box style={{ textAlign: "center", marginTop: "20px", width: "100%" }}>
                        <Chip label="No data found" color="primary" variant="outlined" />
                      </Box>
                    </>}
                </>
              </TabPanel>



            </TabContext>
          </Box>
        </Card> */}

        <Dialog
          open={openModal}
          maxWidth="900px"
          fullScreen
          classes={{ paper: classes.paper }}
        >
          <form onSubmit={handleSubmit}>
            <DialogTitle>Share</DialogTitle>

            <DialogContent
              sx={{ textAlign: 'center', maxWidth: '500px', margin: '0 auto' }}
            >
              <Typography variant="h5" display="block">
                Thanks for buying
              </Typography>
              <Typography display="block">Your Coupon code</Typography>
              <Box
                component="h1"
                sx={{
                  outline: '5px dashed green',
                  padding: '1px',
                  background: '#90EE90',
                }}
              >
                {couponDetails?.code}
              </Box>
              <Box component="h3" sx={{ padding: '1px', color: '#90EE90' }}>
                Please use this coupon code to claim your reward
              </Box>

              <Grid
                container
                alignContent="center"
                justifyContent="center"
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12} md={8}>
                    <Typography
                      variant="overline"
                      display="block"
                      sx={{
                        fontSize: '12px',
                        lineHeight: '1rem',
                        fontWeight: 'bold',
                        color: '#1c1c1c',
                        marginBottom: '10px',
                      }}
                    >
                      Name
                    </Typography>
                    <TextField
                      id="outlined-basic"
                      value={name}
                      onChange={handlePersonalMessageChange}
                      variant="outlined"
                      inputProps={{ maxLength: 100 }}
                      className={classes.input}
                      InputProps={{ classes: { underline: classes.input } }}
                      fullWidth
                      sx={{ border: 'none' }}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Typography
                      variant="overline"
                      display="block"
                      sx={{
                        fontSize: '12px',
                        lineHeight: '1rem',
                        fontWeight: 'bold',
                        color: '#1c1c1c',
                        marginBottom: '10px',
                      }}
                    >
                      Email
                    </Typography>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className={classes.input}
                      InputProps={{ classes: { underline: classes.input } }}
                      fullWidth
                      sx={{ border: 'none' }}
                      onFocus={() => focusChange('email')}
                    />
                  </Grid>
                </Grid>

                <Grid item sm={12} md={8} justifyContent={'center'}>
                  <Typography
                    variant="overline"
                    display="block"
                    sx={{
                      fontSize: '100px',
                      lineHeight: '1rem',
                      fontWeight: 'bold',
                      color: '#1c1c1c',
                      marginBottom: '10px',
                    }}
                  >
                    OR
                  </Typography>
                </Grid>

                <Grid item sm={12} md={8} justifyContent={'center'}>
                  <Typography
                    variant="overline"
                    display="block"
                    sx={{
                      fontSize: '12px',
                      lineHeight: '1rem',
                      fontWeight: 'bold',
                      color: '#1c1c1c',
                      marginBottom: '10px',
                    }}
                  >
                    Phone number
                  </Typography>
                  <MuiPhoneNumber
                    name="phone"
                    id="phone"
                    placeholder="Enter"
                    defaultCountry={'in'}
                    fullWidth
                    variant="outlined"
                    sx={{ mb: 4 }}
                    onChange={handleOnChange}
                  />
                  {/* <Button variant="contained" color="primary" onClick={handleBulkShare}>
                    Bulk Share
                  </Button> */}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} className={classes.submitBtn}>
                close
              </Button>
              <Button type="submit" className={classes.submitBtn}>
                Share
              </Button>
            </DialogActions>
          </form>
        </Dialog>

        {renderRouter && (
          <Router>
            <Routes>
              <Route
                path="/bulkInviteReplica"
                element={<BulkInviteReplica />}
              />
            </Routes>
          </Router>
        )}
      </MainLayout>
    </>
  );

  function DropDown() {
    return (
      <div className="max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="relative flex flex-col space-y-4">
          <div className="relative flex items-center">
            <select
              className="w-full p-2 pr-10 border border-gray-300 rounded-md appearance-none h-10"
              aria-label="Without label"
              value={storeId}
              onChange={onSelectStoreId}
            >
              <option value="">
                <em>Select Store</em>
              </option>
              {stores?.map((store) => (
                <option key={store?.id} value={store?.id}>
                  {store?.branchName}
                </option>
              ))}
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none rounded-r-md h-10">
              <svg
                className="w-6 h-6 text-black font-bold"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
