import { Box, Button, Card, Chip, makeStyles } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { Ippopay } from 'react-ippopay';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import MainLayout from '../../layout/mainLayout';
import {
  makePaymentWithoutFee,
  paymentInfo,
} from '../../service_config/admin.service';
import { post, put } from '../../service_config/http.service';

const useStyles = makeStyles((theme) => ({
  cards: {
    marginTop: '100px',
  },
  card: {
    width: '100%',
    padding: '50px',
    margin: '0 auto',
  },
  btn: {
    background: 'white',
    color: 'blue',
    border: '1px solid blue',

    '&:hover': {
      background: 'blue',
      color: 'white',
    },
  },
  paybtn: {
    background: 'blue',
    color: 'white',

    '&:hover': {
      background: 'blue',
      color: 'white',
    },
  },
}));

export default function Payment() {
  const { state } = useLocation();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  let { id } = useParams();
  const [paymentDetail, setPaymentDetail] = React.useState();
  const [isCash, setIsCash] = React.useState(true);
  // const [payStart, setPayStart] = React.useState(false);
  const [paymentInit, setPaymentInit] = React.useState();
  const [userCredit, setUserCredit] = React.useState(true);
  const [ippopay, setIppoPay] = React.useState({
    ippopayOpen: false,
    order_id: '',
    public_key: '',
  });
  const [selectedPay, setSelectedPay] = React.useState();

  const [isCreditSelected, setIsCreditSelected] = React.useState(false);
  const [isPaid, setIsPaid] = React.useState(true);
  const [paymentDetails, setPaymentDetails] = React.useState({});
  const dispatch = useDispatch();
  const payment = useSelector((state) => state.paymentDetail.paymentDetail);

  const toggleCredit = () => {
    setIsCreditSelected(!isCreditSelected);
    setIsCash(!isCash);
    setUserCredit(!userCredit);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const payDetails = async () => {
    let payLoadData = {
      payload: {
        useCredits: userCredit,
      },
      campaignId: state?.campaignId,
    };
    setIsLoading(true);
    const response = await paymentInfo(payLoadData);
    if (!response.error) {
      setIsLoading(false);
      toast.success('Please Complete the payment to make your campaign live');
      toast.info('You can complete pending payment from View Campaigns page');
      setIsPaid(false);
      setPaymentDetails(response.data.result);
    } else {
      setIsLoading(false);
      toast.error(response.msg);
      // navigate("/");
    }
  };
  const payWithFreeCampaign = async () => {
    let payLoadData = {
      campaignId: state?.campaignId,
    };
    const response = await makePaymentWithoutFee(payLoadData);

    if (response?.data.msg === 'Failed') {
      toast.error(response?.data?.result);
    } else if (!response?.data.error) {
      setOpen(true);
    } else {
      toast.error('Payment failed try again');
      // navigate("/");
    }
  };
  const backTohome = () => {
    navigate('/create-store');
  };

  React.useEffect(() => {
    const campaignId = state?.campaignId;

    payDetails();
    window.addEventListener('message', ippopayHandler);
    return () => {
      setIppoPay({
        ippopayOpen: false,
        order_id: '',
        public_key: '',
      });
    };
  }, [userCredit]);

  const initiatePayment = (e) => {
    e.preventDefault();
    const campaignId = state?.campaignId;
    let paymentData = {};
    if (isCash) paymentData = payment.fee.withoutCredit;
    else paymentData = payment.fee.withCredit;
    setSelectedPay(paymentData);
    post(`/campaign/${campaignId}/pay`, {
      campaignId,
      paymentData,
      advertiserId: payment.campaign.advertiserId,
    })
      .then((response) => {
        setPaymentInit(response.data.payload.order);
        setIppoPay({
          ippopayOpen: true,
          order_id: response.data.payload.data.order.order_id,
          public_key: response.data.payload.data.order.public_key,
        });
      })
      .catch((e) => {
        toast.error(e.response.data.payload);
      });
  };

  const ippopayHandler = (e) => {
    const campaignId = state?.campaignId;
    if (e.data.status == 'success') {
      toast.success(e.data.status);
      put(`/campaign/${campaignId}/pay`, {
        gatewayData: e.data,
        campaignId,
        paymentData: selectedPay,
        advertiserId: payment?.campaign?.advertiserId,
      })
        .then((response) => {
          toast.success(response.data.payload);
        })
        .catch((e) => {
          toast.success(e.response.data.payload);
        });
    }
    if (e.data.status == 'failure') {
      toast.error(e.data.status);
    }
  };

  const CampaignCostWithoutCredit = ({ pay }) => {
    // const b = paymentDetail?.fee?.withoutCredit?.pay;

    return (
      <TableContainer>
        <Table
          sx={{
            maxWidth: 400,
            margin: '0 auto',
            boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)',
          }}
        >
          <TableBody>
            {/* <TableRow>
              <TableCell component="th" scope="row">
                Fee
              </TableCell>
              <TableCell
                align="center"
                sx={{ background: "#c2c0c0", color: "white" }}
              >
                {paymentDetails?.totalWithOutBaeonCommission}
              </TableCell>
            </TableRow> */}

            <TableRow>
              <TableCell component="th" scope="row">
                BAEON Fee
              </TableCell>
              <TableCell
                align="center"
                sx={{ background: '#c2c0c0', color: 'white' }}
              >
                {`${paymentDetails?.baeonCommission + paymentDetails?.totalWithOutBaeonCommission}`}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                GST(18%)
              </TableCell>
              <TableCell
                align="center"
                sx={{ background: '#c2c0c0', color: 'white' }}
              >
                {paymentDetails?.gst}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                NO OF COUPONS
              </TableCell>
              <TableCell
                align="center"
                sx={{ background: '#c2c0c0', color: 'white' }}
              >
                {state.couponsCount}
                {/* {paymentDetails?.isFreeCampaign?"YES":"NO"} */}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
                Credit{' '}
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.btn}
                  // onClick={toggleCredit}
                >
                  Included credit
                </Button>
              </TableCell>
              <TableCell
                align="center"
                sx={{ background: '#c2c0c0', color: 'white' }}
              >
                {paymentDetails?.credits}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row" sx={{ fontSize: '25px' }}>
                Total
              </TableCell>
              <TableCell
                align="center"
                sx={{ background: '#c2c0c0', color: 'white', fontSize: '25px' }}
              >
                {paymentDetails?.grandTotal}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const CampaignCostWithCredit = ({ pay }) => {
    // const a = paymentDetail?.fee?.withCredit?.pay;
    return (
      <TableContainer>
        <Table
          sx={{
            maxWidth: 400,
            margin: '0 auto',
            boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)',
          }}
        >
          <TableBody>
            {/* <TableRow> */}
            {/* <TableCell component="th" scope="row">
                Fee
              </TableCell>
              <TableCell
                align="center"
                sx={{ background: "#c2c0c0", color: "white" }}
              >
                {paymentDetails?.totalWithOutBaeonCommission}
              </TableCell>
            </TableRow> */}

            <TableRow>
              <TableCell component="th" scope="row">
                BAEON Fee
              </TableCell>
              <TableCell
                align="center"
                sx={{ background: '#c2c0c0', color: 'white' }}
              >
                {`${paymentDetails?.baeonCommission + paymentDetails?.totalWithOutBaeonCommission}`}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                GST(18%)
              </TableCell>
              <TableCell
                align="center"
                sx={{ background: '#c2c0c0', color: 'white' }}
              >
                {paymentDetails?.gst}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                NO OF COUPONS
              </TableCell>
              <TableCell
                align="center"
                sx={{ background: '#c2c0c0', color: 'white' }}
              >
                {state.couponsCount}
                {/* {paymentDetails?.isFreeCampaign?"YES":"NO"} */}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
                Credit{' '}
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.btn}
                  onClick={toggleCredit}
                >
                  Exclude Credit
                </Button>
              </TableCell>
              <TableCell
                align="center"
                sx={{ background: '#c2c0c0', color: 'white' }}
              >
                {paymentDetails?.credits}
              </TableCell>
            </TableRow>
            {/* <TableRow>
              <TableCell component="th" scope="row">
                 IS FREE CAMPAIGNS
               
              </TableCell>
              <TableCell
                align="center"
                sx={{ background: "#c2c0c0", color: "white" }}
              >
               {paymentDetails?.isFreeCampaign?"YES":"NO"}
              </TableCell>
            </TableRow> */}

            <TableRow>
              <TableCell component="th" scope="row" sx={{ fontSize: '25px' }}>
                Total
              </TableCell>
              <TableCell
                align="center"
                sx={{ background: '#c2c0c0', color: 'white', fontSize: '25px' }}
              >
                {paymentDetails?.grandTotal}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const PayComp = () => {
    return (
      <div>
        {isCreditSelected ? (
          <CampaignCostWithCredit pay={payment?.fee?.withCredit?.pay} />
        ) : (
          <CampaignCostWithoutCredit pay={payment?.fee?.withoutCredit?.pay} />
        )}
      </div>
    );
  };

  return (
    <>
      <MainLayout>
        <div className={classes.cards}>
          {!isLoading ? (
            <Card variant="outlined" className={classes.card}>
              <Box sx={{ textAlign: 'center', marginBottom: '30px' }}>
                <Chip label="FREE CAMPAIGN" color="primary" />
              </Box>
              <PayComp></PayComp>

              {paymentDetails?.isFreeCampaign ? (
                <Box sx={{ textAlign: 'center', marginTop: '30px' }}>
                  <div></div>
                  <Button
                    size="large"
                    className={classes.paybtn}
                    onClick={payWithFreeCampaign}
                  >
                    Proceed to pay {`Rs:${paymentDetails?.grandTotal}`}
                  </Button>
                </Box>
              ) : (
                <Box sx={{ textAlign: 'center', marginTop: '30px' }}>
                  <div></div>
                  <Button
                    size="large"
                    className={classes.paybtn}
                    onClick={(e) => initiatePayment(e)}
                  >
                    Proceed to pay {`Rs:${paymentDetails?.grandTotal}`}
                  </Button>
                  <Ippopay
                    ippopayOpen={ippopay.ippopayOpen}
                    ippopayClose={true}
                    order_id={ippopay.order_id}
                    public_key={ippopay.public_key}
                    style={{ cursor: 'pointer' }}
                  />
                </Box>
              )}
            </Card>
          ) : (
            <Box sx={{ textAlign: 'center', marginBottom: '30px' }}>
              <Chip label="LOADING" color="primary" />
            </Box>
          )}
        </div>
      </MainLayout>

      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Payment Status</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box textAlign={'center'}>
              <Box sx={{ marginBottom: '30px' }}>
                Your transaction has been completed
              </Box>
              <Button className={classes.paybtn} onClick={backTohome}>
                Back to home
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Close</Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
}
