import { useState } from 'react';
import BaeonnLogo from '../../../assets/baeon-full.jpg';

const countryCodes = [
  { code: '+91', country: 'India' },
  // Add more country codes here
];

const NumberInputForm = ({ onSubmit, generateQr }) => {
  const [phone, setPhone] = useState('');
  const [selectedCode, setSelectedCode] = useState(countryCodes[0].code);

  const handleChange = (e) => {
    setPhone(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(`${selectedCode}${phone}`);
  };

  return (
    <div className="bg-white md:shadow-lg rounded-lg p-8 md:p-8 w-full max-w-md ml-auto mr-0 md:mr-10">
      {' '}
      {/* Centered form with max width */}
      <img
        src={BaeonnLogo}
        alt="Baeonn Logo"
        className="mb-4 mx-auto rounded"
        style={{ maxWidth: '200px' }}
      />
      <h2 className="text-2xl font-bold mb-4">LOG IN / SIGN UP</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2" htmlFor="phone">
            Phone Number
          </label>
          <div className="flex">
            <select
              value={selectedCode}
              onChange={(e) => setSelectedCode(e.target.value)}
              className="p-2 border rounded-l bg-gray-100 text-black pr-8"
            >
              {countryCodes.map((code) => (
                <option key={code.code} value={code.code}>
                  {code.code}
                </option>
              ))}
            </select>
            <input
              type="text"
              value={phone}
              onChange={handleChange}
              className="w-full p-3 border rounded-r"
              placeholder="Enter phone number"
            />
          </div>
        </div>
        <button className="w-full bg-primary text-white p-3 rounded-lg mb-4">
          Send OTP
        </button>
      </form>
      <button
        className="w-full bg-primary text-white p-3 rounded-lg mb-4"
        onClick={generateQr}
      >
        Generate QR
      </button>
      <p className="mt-4 text-gray-600 text-sm">
        By Signing up you accept our{' '}
        <a href="/terms-and-conditions" className="text-primary">
          Terms & Conditions
        </a>
      </p>
      <p className="mt-4 text-gray-600 text-sm">
        <a href="/nearByStores" className="text-primary">
          View stores nearby
        </a>
      </p>
    </div>
  );
};

export default NumberInputForm;
