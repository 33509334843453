import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  FormControlLabel,
  Grid,
  Input,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  makeStyles,
} from '@material-ui/core';
import BackupIcon from '@mui/icons-material/Backup';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import {
  getBussinessDetail,
  submitBusinessDetail,
} from '../../service_config/admin.service';
import ErrorField from '../errorField';
import FileUploadHoc from '../File.Upload';
import Lable from '../Typography/lable';
import {
  businessDetailInitial,
  businessInputArray,
  bussinessSchema,
} from './validation';

const useStyles = makeStyles((theme) => ({
  lableTitle: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontWeight: 'bold',
    color: '#57748a',
  },
  lable: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontWeight: 'bold',
    color: '#57748a',
  },
  cards: {
    marginTop: '100px',
  },

  card: {
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
    borderRadius: '5px',
    padding: '20px',
  },
  canc: {
    background: 'blue',
    color: 'white',
    fontWeight: '700',
    '&:hover': {
      background: 'blue',
      color: 'white',
    },
  },
  inputfile: {
    display: 'none',
  },
  upload: {
    background: '#f0f3f5',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  radio: {
    '&$checked': {
      color: '#4B8DF8',
    },
  },
  checked: {},
}));

export default function CardBusinessDetails() {
  const classes = useStyles();
  const [image, setImage] = React.useState(0);
  const businessDetail = useSelector(
    (state) => state.settings.businessDetail.gst
  );
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { ...businessDetailInitial },
    resolver: yupResolver(bussinessSchema),
    mode: 'onBlur',
  });
  const businessDetailValue = watch();

  const setimageUrl = (data) => {
    setValue('url', data);
  };

  const getBD = async () => {
    const response = await getBussinessDetail();
    if (!response.error) {
      reset({ ...response.result.businessDetails.gst });
    }
  };
  React.useEffect(() => {
    getBD();
  }, []);

  const handleSubmitForm = async (data) => {
    const res = await submitBusinessDetail({ gst: data, tds: '' });
    if (!res.error) {
      toast.success(res.msg);
    } else {
      toast.error(res.msg);
    }
  };
  const onSubmit = (data) => {
    handleSubmitForm(data);
  };

  return (
    <>
      <ToastContainer></ToastContainer>
      <Card>
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              className={classes.grid}
            >
              <Grid item xs={6}>
                <Typography
                  variant="h6"
                  display="block"
                  sx={{
                    // fontSize: "12px",
                    lineHeight: '1rem',
                    fontWeight: 'bold',
                    color: '#1c1c1c',
                  }}
                  className={classes.lable}
                >
                  Business Details
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ textAlign: 'end' }}>
                  <Button size="small" type="submit">
                    update
                  </Button>
                </Box>
              </Grid>
            </Grid>{' '}
            <Box p={2}>
              <Typography
                variant="p"
                display="block"
                className={classes.lableTitle}
              >
                GST DETAILS
              </Typography>
              <Box p={2}>
                <Lable
                  style={{ my: 2, marginRight: 'auto' }}
                  title="IS YOUR BUSINESS GST EXEMPTED?"
                />

                <RadioGroup
                  row
                  // aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={businessDetail.exempted ? 'yes' : 'no'}
                  name="radio-buttons-group"
                  onChange={(e) =>
                    setValue(
                      'exempted',
                      e.target.value === 'yes' ? true : false
                    )
                  }
                >
                  <FormControlLabel
                    value="yes"
                    control={
                      <Radio
                        classes={{
                          root: classes.radio,
                          checked: classes.checked,
                        }}
                      />
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    value="no"
                    control={
                      <Radio
                        classes={{
                          root: classes.radio,
                          checked: classes.checked,
                        }}
                      />
                    }
                    label="No"
                  />
                </RadioGroup>
                <div>
                  {businessInputArray.map((data, index) => {
                    let { grid, items } = data;

                    if (grid) {
                      return (
                        <div key={index}>
                          <Grid container spacing={2}>
                            {items.map((field) => {
                              let error = field?.nestedObj
                                ? errors[field?.name?.split('.')[0]]?.[
                                    field?.name?.split('.')[1]
                                  ]
                                  ? true
                                  : false
                                : errors[field.name]
                                  ? true
                                  : false;
                              let errorMessage = field?.nestedObj
                                ? errors[field?.name?.split('.')[0]]?.[
                                    field?.name?.split('.')[1]
                                  ]
                                : errors[field.name];

                              if (field.type === 'input') {
                                return (
                                  <>
                                    <Grid item xs={field.xs} md={field.md}>
                                      <Lable
                                        style={{ my: 2, marginRight: 'auto' }}
                                        title={field.lable}
                                      />
                                      <Controller
                                        name={field.name}
                                        control={control}
                                        render={({
                                          field: { onChange, value },
                                        }) => (
                                          <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            name={field.name}
                                            type={field.inputType}
                                            value={value}
                                            fullWidth
                                            className={classes.input}
                                            disabled={
                                              field.disabled ? true : false
                                            }
                                            // InputProps={{ classes: { underline: classes.input } }}
                                            sx={{
                                              border: 'none',
                                            }}
                                            error={error}
                                            onChange={onChange}
                                          />
                                        )}
                                      />
                                      {error && (
                                        <ErrorField
                                          err={errorMessage?.message}
                                        />
                                      )}
                                    </Grid>
                                  </>
                                );
                              }
                              if (field.type === 'select') {
                                return (
                                  <>
                                    <Grid item xs={field.xs} md={field.md}>
                                      <Lable
                                        style={{ my: 2, marginRight: 'auto' }}
                                        title={field.lable}
                                      />

                                      <Controller
                                        name={field.name}
                                        control={control}
                                        render={({
                                          field: { onChange, value },
                                        }) => (
                                          <Select
                                            value={value}
                                            // onChange={handleChange}
                                            displayEmpty
                                            inputProps={{
                                              'aria-label': 'Without label',
                                            }}
                                            fullWidth
                                            variant="outlined"
                                            error={error}
                                            onChange={onChange}
                                          >
                                            <MenuItem value="">
                                              <em>None</em>
                                            </MenuItem>
                                            {field.listData.map(
                                              (option, index) => (
                                                <MenuItem
                                                  value={option}
                                                  key={index}
                                                >
                                                  {option}
                                                </MenuItem>
                                              )
                                            )}
                                          </Select>
                                        )}
                                      />
                                      {error && (
                                        <ErrorField
                                          err={errorMessage?.message}
                                        />
                                      )}
                                    </Grid>
                                  </>
                                );
                              }

                              if (field.type === 'fileUpload') {
                                return (
                                  <>
                                    <Grid item xs={field.xs} md={field.md}>
                                      <FileUploadHoc
                                        fileType={field.id}
                                        set={setimageUrl}
                                      >
                                        <Box
                                          component="label"
                                          sx={{
                                            width: '90%',
                                            margin: '0 auto',
                                            padding: '10px',
                                            background: 'white',
                                            height: '100px',
                                            boxShadow:
                                              '0 3px 10px rgb(0 0 0 / 0.2)',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                          }}
                                          htmlFor={field.id}
                                          mt={2}
                                        >
                                          <BackupIcon
                                            sx={{
                                              fontSize: '40px',
                                              color: 'blue',
                                            }}
                                          />

                                          <Lable
                                            style={{
                                              my: 2,
                                              textAlign: 'center',
                                            }}
                                            title={field.label}
                                          />

                                          <Input
                                            accept="image/*"
                                            id={field.id}
                                            type="file"
                                            className={classes.inputfile}
                                          />
                                        </Box>
                                        {error && (
                                          <ErrorField
                                            err={errorMessage?.message}
                                          />
                                        )}
                                      </FileUploadHoc>
                                      {businessDetailValue[field?.name]
                                        ? 'Last uploaded:'
                                        : ''}{' '}
                                      <span>
                                        {businessDetailValue[field?.name] ? (
                                          <a
                                            href={
                                              businessDetailValue[field?.name]
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            Click Here
                                          </a>
                                        ) : (
                                          ''
                                        )}
                                      </span>
                                    </Grid>
                                  </>
                                );
                              }

                              return <></>;
                            })}
                          </Grid>
                        </div>
                      );
                    }

                    return <></>;
                  })}
                </div>
              </Box>
            </Box>
          </form>
        </CardContent>
      </Card>
    </>
  );
}
