import { useState, useRef, useEffect } from 'react';
import showToast from '../../../utilities/toast';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Html5QrcodeScanner } from 'html5-qrcode';

const AttachQr = () => {
  const [isCameraActive, setIsCameraActive] = useState(true);
  const [result, setResult] = useState(null);
  const lastErrorTimeRef = useRef(null);
  const [closing, setClosing] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const store_id = queryParams.get('store_id');

  const closeWindow = () => {
    setClosing(true);
    setTimeout(() => {
      window.close();
    }, 5000);
  };

  const makeStoreQrMappingRequet = async (qr_data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}stores/qr-store-mapping`,
        {
          store_id: store_id,
          qr_uuid: qr_data,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200 && response.data.error === false) {
        showToast('QR Code successfully mapped!');
        setResult({ error: false, msg: 'QR Code successfully mapped!' });
      } else {
        showToast('Failed to map QR Code.', 'error');
        setResult({
          error: true,
          msg: response?.data?.result || 'Failed to map QR Code.',
        });
      }
      closeWindow();
    } catch (error) {
      showToast('An error occurred while mapping the QR Code.', 'error');
      closeWindow();
    }
  };

  const handleSuccess = (data) => {
    showToast('Scanned succesfully', 'success');
    setIsCameraActive(false); // Close the camera
    makeStoreQrMappingRequet(data);
  };

  const handleError = (_error) => {
    const now = new Date().getTime();
    const interval = 10000; // 10 seconds in milliseconds
    if (
      !lastErrorTimeRef.current ||
      now - lastErrorTimeRef.current > interval
    ) {
      lastErrorTimeRef.current = now;
      showToast('Please scan a QR', 'error');
    }
  };

  useEffect(() => {
    const scanner = new Html5QrcodeScanner(
      'qr-reader',
      { fps: 10, qrbox: 250 },
      /* verbose= */ false
    );

    scanner.render(
      (decodedText, _decodedResult) => {
        handleSuccess(decodedText);
      },
      (errorMessage) => {
        handleError(errorMessage);
      }
    );
  }, []);

  return (
    <>
      <div id="qr-reader" className="w-full h-full"></div>
      <p className="text-center text-lg font-semibold text-gray-800 p-4 bg-white rounded shadow-md">
        {result
          ? result.msg
          : 'Please scan a QR code to attach it to the store.'}
      </p>
      {closing && (
        <p className="text-center text-lg font-semibold text-gray-800 p-4 bg-white rounded shadow-md">
          {`Windows will be auto closed in 5 seconds.`}
        </p>
      )}
    </>
  );

  //   <>
  //     {isCameraActive && (
  //       <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
  //         <div className="w-full h-full">
  //           <QrReader
  //             onResult={(result, error) => {
  //               if (result) {
  //                 handleSuccess(result?.text);
  //               }

  //               if (error) {
  //                 handleError(error);
  //               }
  //             }}
  //             style={{ width: '100%', height: '100%' }}
  //           />
  //         </div>
  //       </div>
  //     )}
  //     <p className="text-center text-lg font-semibold text-gray-800 p-4 bg-white rounded shadow-md">
  //       {result
  //         ? capitalizeWords(result.msg)
  //         : 'Please scan a QR code to attach it to the store.'}
  //     </p>
  //     {closing && (
  //       <p className="text-center text-lg font-semibold text-gray-800 p-4 bg-white rounded shadow-md">
  //         {`Windows will be auto closed in 5 seconds.`}
  //       </p>
  //     )}
  //   </>
  // );
};

export default AttachQr;
