import { useLocation } from 'react-router-dom';
import BaeonnQR from '../../auth/QRCodeGen/BaeonnQr';

const PrintQr = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
      <BaeonnQR qr={code} />
    </div>
  );
};

export default PrintQr;
