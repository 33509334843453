import React from 'react';
import MainLayout from '../../../layout/mainLayout';
import { claimCoupon } from '../../../service_config/admin.service';
import showToast from '../../../utilities/toast';

export default function ClaimCoupon() {
  const [coupon, setCoupon] = React.useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!coupon || coupon.length === 0) {
      showToast('Please enter coupon code', 'error');
      return;
    }
    claimCoupon({ code: coupon })
      .then((res) => {
        if (!res.data.error) {
          let msg = { err: false, msg: '' };
          if (res.status === 200) {
            msg.msg = 'Coupon has been claimed successfully';
          } else if (res.status === 409) {
            msg.err = true;
            msg.msg = 'Coupon has been claimed already';
          } else if (res.status === 500) {
            msg.err = true;
            msg.msg = 'Please try after some time';
          } else {
            msg.err = true;
            msg.msg = 'Enter a valid code';
          }
          if (msg.err) showToast(msg.msg, 'error');
          if (!msg.err) showToast(msg.msg, 'success');
        } else {
          showToast(res.data.result, 'error');
        }
      })
      .catch((err) => {
        showToast('Something went wrong', 'error');
      });
  };

  return (
    <MainLayout>
      <div className="flex justify-center items-center mt-24">
        <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-md">
          <form onSubmit={handleSubmit} className="w-full">
            <div className="mb-4">
              <input
                className="border rounded p-2 w-full"
                label="Find Coupon"
                type="search"
                onChange={(e) => setCoupon(e.target.value)}
                placeholder="Find Coupon*"
              />
            </div>
            <div className="text-center">
              <button
                className="bg-primary text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
                type="submit"
              >
                Claim Coupon
              </button>
            </div>
          </form>
        </div>
      </div>
    </MainLayout>
  );
}
