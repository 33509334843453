import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import AuthLayout from '../../layout/authlayout';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Lable from '../../components/Typography/lable';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  getUserDetails,
  submitBusinessOwnerDetails,
} from '../../service_config/admin.service';
import { businessSchema, inputFormArray, initialState } from './validation';

export default function BusinessDetail() {
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const referralCode = searchParams.get('refCode');

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: { ...initialState },
    resolver: yupResolver(businessSchema),
    mode: 'onBlur',
  });

  const handleSubmitForm = async (data) => {
    const response = await submitBusinessOwnerDetails(data);

    if (!response.error) {
      toast.success(response.msg);

      if (response.msg === 'Success') {
        if (response?.result?.businessId) {
          let newObject = JSON.parse(localStorage.getItem('baeon_user'));
          newObject = {
            ...newObject,
            businessId: response?.result?.businessId,
          };
          localStorage.setItem('baeon_user', JSON.stringify(newObject));
        }

        getUserDetails();
        navigate(`/create-store`);
      }
    } else {
      toast.error(response.msg);
      if (response.result === 'Invalid referral code') {
        setError('referralCode', { type: 'custom', message: response.result });
      }
    }
  };

  React.useEffect(() => {
    if (referralCode) {
      setValue('referralCode', referralCode);
    }
  }, []);

  const onSubmit = (data) => {
    handleSubmitForm(data);
  };

  return (
    <>
      <AuthLayout>
        <Box
          component="form"
          autoComplete="off"
          sx={{ mb: 2, width: '100%' }}
          onSubmit={handleSubmit(onSubmit)}
        >
          {inputFormArray.map((input) => {
            return (
              <>
                <Lable
                  style={{ my: 1, display: 'block' }}
                  title={input.lable}
                  key={input.lable}
                />
                <Controller
                  name={input.name}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      placeholder={input.placeholder}
                      name={input.name}
                      fullWidth
                      value={value}
                      onChange={onChange}
                      error={errors[input.name] ? true : false}
                      helperText={errors[input.name]?.message}
                    />
                  )}
                />
              </>
            );
          })}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            style={{ fontSize: '18px' }}
            sx={{ mt: 3 }}
          >
            Submit
          </Button>
        </Box>
      </AuthLayout>
    </>
  );
}
