import Stack from '@mui/material/Stack';
import React, { useState } from 'react';
import MainLayout from '../../layout/mainLayout';

import {
  Box,
  Button,
  Card,
  Grid,
  TextareaAutosize,
  makeStyles,
} from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Lable from '../../components/Typography/lable';
import { inviteStore } from '../../service_config/admin.service';

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: '100%',
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
    minHeight: '100vh',
    // margin: "auto",
    marginTop: '100px',
    borderRadius: '5px',
    padding: '20px',
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'start',
  },
  invitebtn: {
    background: '#1456db',
    color: 'white',
    fontWeight: '700',
    '&:hover': {
      background: '#1456db',
      color: 'white',
    },
  },
  inputfile: {
    display: 'none',
  },
  upload: {
    background: '#f0f3f5',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    borderRadius: 'none',
  },
}));

export default function BulkInvite() {
  const classes = useStyles();
  let navigate = useNavigate();
  let { state } = useLocation();
  const [emails, setEmails] = useState();
  const [file, setFile] = React.useState();
  const onChange = (e) => {
    setFile(e.target.files[0]);
  };
  const onSubmit = async (e) => {
    e.preventDefault();

    const emailarr = emails.split(',');
    let data = {
      storeType: `${state.action}`,
      emails: emailarr,
    };
    const res = await inviteStore(data);
    if (!res.error) {
      toast.success(res.msg);
      navigate('/store');
    } else {
      toast.error(res.msg);
    }
  };

  const handelChange = (e) => {
    setEmails(e.target.value);
  };

  return (
    <MainLayout>
      <form onSubmit={onSubmit}>
        <Card variant="outlined" className={classes.card}>
          <Grid container>
            <Grid item sm={12} md={6}>
              <Stack spacing={2} padding={3}>
                <Lable
                  style={{ my: 2, display: 'block' }}
                  title={`${state.action === 'franchise' ? 'Invite franchise Store Managers' : 'Invite  Store Managers'}`}
                />
                <Box sx={{ maxWidth: '1000px' }}>
                  <Lable
                    style={{ my: 2, display: 'block' }}
                    title="Enter Email"
                  />
                  <TextareaAutosize
                    aria-label="Enter Email's"
                    minRows={7}
                    placeholder="Enter Email's"
                    style={{ width: '100%' }}
                    fullWidth
                    value={emails}
                    onChange={handelChange}
                  />
                </Box>
                <Lable
                  style={{ my: 2, display: 'block' }}
                  title=" Invite Multiple Store Managers by separating Email IDs with a Comma."
                />
                <Lable
                  style={{ my: 2, display: 'block', color: 'red' }}
                  title="Eg: xyz@gmail.com, abc@gmail.com"
                />
                <Button type="submit" className={classes.invitebtn}>
                  Send Invite
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Card>
      </form>
    </MainLayout>
  );
}
