export const truncateString = (str, num) =>
  str?.length > num ? str.slice(0, num > 3 ? num - 3 : num) + '...' : str;

export const splitCoupons = (noOfCoupons, mediaPartners) => {
  const result = [];
  let index = 0;
  for (let i = 0; i < noOfCoupons % mediaPartners.length; i += 1) {
    result.push({
      ...mediaPartners[index],
      noOfCoupons: Math.ceil(noOfCoupons / mediaPartners.length),
    });
    index += 1;
  }
  for (
    let i = 0;
    i < mediaPartners.length - (noOfCoupons % mediaPartners.length);
    i += 1
  ) {
    result.push({
      ...mediaPartners[index],
      noOfCoupons: Math.floor(noOfCoupons / mediaPartners.length),
    });
    index += 1;
  }
  return result;
};

export const capitalizeWords = (str) => {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};
