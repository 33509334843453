import { Box, Card, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import MainLayout from '../../layout/mainLayout';

import CompanyLogo from '../../assets/ill_header.png';

const useStyles = makeStyles((theme) => ({
  cards: {
    marginTop: '100px',
  },
  card: {
    width: '100%',
    padding: '10px',
    background: '#382b5c',
    minHeight: '100vh',
  },
  title: {
    color: 'white',
    fontWeight: 'bold',
  },
  lable: {
    color: '#161517',
    fontWeight: 'bold',
  },
  companyLogo: {
    width: '200px',
    height: '200px',
    objectFit: 'cover',
    borderRadius: '50%',
  },
}));

export default function PrimeCustomer() {
  const classes = useStyles();

  return (
    <>
      <MainLayout>
        <div className={classes.cards}>
          <Card variant="outlined" className={classes.card}>
            <Typography
              variant="h6"
              display="block"
              sx={{
                // fontSize: "12px",
                lineHeight: '1rem',
                fontWeight: 'bold',
                color: 'white',
              }}
              className={classes.title}
            >
              Bea mart
            </Typography>
            <Box>
              <Grid
                container
                spacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                className={classes.grid}
              >
                <Grid item xs={12} sm={6} md={3}>
                  <Box sx={{ textAlign: 'center' }}>
                    <Card variant="outlined">
                      <img
                        src={CompanyLogo}
                        alt="companyLogo"
                        className={classes.companyLogo}
                      />
                      <Typography
                        variant="h5"
                        display="block"
                        sx={{
                          // fontSize: "12px",
                          lineHeight: '1rem',
                          fontWeight: 'bold',
                          color: '#161517',
                        }}
                        className={classes.lable}
                      >
                        Star-Bucks Coffee
                      </Typography>
                      <Typography
                        variant="p"
                        display="block"
                        className={classes.lable}
                      >
                        Category - Cafe
                      </Typography>
                    </Card>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Box sx={{ textAlign: 'center' }}>
                    <Card variant="outlined">
                      <img
                        src={CompanyLogo}
                        alt="companyLogo"
                        className={classes.companyLogo}
                      />
                      <Typography
                        variant="h5"
                        display="block"
                        sx={{
                          // fontSize: "12px",
                          lineHeight: '1rem',
                          fontWeight: 'bold',
                          color: '#161517',
                        }}
                        className={classes.lable}
                      >
                        Star-Bucks Coffee
                      </Typography>
                      <Typography
                        variant="p"
                        display="block"
                        className={classes.lable}
                      >
                        Category - Cafe
                      </Typography>
                    </Card>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Box sx={{ textAlign: 'center' }}>
                    <Card variant="outlined">
                      <img
                        src={CompanyLogo}
                        alt="companyLogo"
                        className={classes.companyLogo}
                      />
                      <Typography
                        variant="h5"
                        display="block"
                        sx={{
                          // fontSize: "12px",
                          lineHeight: '1rem',
                          fontWeight: 'bold',
                          color: '#161517',
                        }}
                        className={classes.lable}
                      >
                        Star-Bucks Coffee
                      </Typography>
                      <Typography
                        variant="p"
                        display="block"
                        className={classes.lable}
                      >
                        Category - Cafe
                      </Typography>
                    </Card>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Box sx={{ textAlign: 'center' }}>
                    <Card variant="outlined">
                      <img
                        src={CompanyLogo}
                        alt="companyLogo"
                        className={classes.companyLogo}
                      />
                      <Typography
                        variant="h5"
                        display="block"
                        sx={{
                          // fontSize: "12px",
                          lineHeight: '1rem',
                          fontWeight: 'bold',
                          color: '#161517',
                        }}
                        className={classes.lable}
                      >
                        Star-Bucks Coffee
                      </Typography>
                      <Typography
                        variant="p"
                        display="block"
                        className={classes.lable}
                      >
                        Category - Cafe
                      </Typography>
                    </Card>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Box sx={{ textAlign: 'center' }}>
                    <Card variant="outlined">
                      <img
                        src={CompanyLogo}
                        alt="companyLogo"
                        className={classes.companyLogo}
                      />
                      <Typography
                        variant="h5"
                        display="block"
                        sx={{
                          // fontSize: "12px",
                          lineHeight: '1rem',
                          fontWeight: 'bold',
                          color: '#161517',
                        }}
                        className={classes.lable}
                      >
                        Star-Bucks Coffee
                      </Typography>
                      <Typography
                        variant="p"
                        display="block"
                        className={classes.lable}
                      >
                        Category - Cafe
                      </Typography>
                    </Card>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Box sx={{ textAlign: 'center' }}>
                    <Card variant="outlined">
                      <img
                        src={CompanyLogo}
                        alt="companyLogo"
                        className={classes.companyLogo}
                      />
                      <Typography
                        variant="h5"
                        display="block"
                        sx={{
                          // fontSize: "12px",
                          lineHeight: '1rem',
                          fontWeight: 'bold',
                          color: '#161517',
                        }}
                        className={classes.lable}
                      >
                        Star-Bucks Coffee
                      </Typography>
                      <Typography
                        variant="p"
                        display="block"
                        className={classes.lable}
                      >
                        Category - Cafe
                      </Typography>
                    </Card>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Box sx={{ textAlign: 'center' }}>
                    <Card variant="outlined">
                      <img
                        src={CompanyLogo}
                        alt="companyLogo"
                        className={classes.companyLogo}
                      />
                      <Typography
                        variant="h5"
                        display="block"
                        sx={{
                          // fontSize: "12px",
                          lineHeight: '1rem',
                          fontWeight: 'bold',
                          color: '#161517',
                        }}
                        className={classes.lable}
                      >
                        Star-Bucks Coffee
                      </Typography>
                      <Typography
                        variant="p"
                        display="block"
                        className={classes.lable}
                      >
                        Category - Cafe
                      </Typography>
                    </Card>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Box sx={{ textAlign: 'center' }}>
                    <Card variant="outlined">
                      <img
                        src={CompanyLogo}
                        alt="companyLogo"
                        className={classes.companyLogo}
                      />
                      <Typography
                        variant="h5"
                        display="block"
                        sx={{
                          // fontSize: "12px",
                          lineHeight: '1rem',
                          fontWeight: 'bold',
                          color: '#161517',
                        }}
                        className={classes.lable}
                      >
                        Star-Bucks Coffee
                      </Typography>
                      <Typography
                        variant="p"
                        display="block"
                        className={classes.lable}
                      >
                        Category - Cafe
                      </Typography>
                    </Card>
                  </Box>
                </Grid>
              </Grid>{' '}
            </Box>
          </Card>
        </div>
      </MainLayout>
    </>
  );
}
