const Card = ({ item }) => {
  return (
    <div className="p-4">
      <div className="bg-white p-6 rounded-lg shadow-lg flex items-center space-x-4">
        <div className="flex-shrink-0">
          <div className="bg-blue-500 rounded-full h-16 w-16 flex items-center justify-center">
            <img
              src={item.imageUrl}
              alt="Logo"
              className="h-12 w-12 rounded-full"
            />
          </div>
        </div>
        <div className="flex-1 min-w-0">
          <div className="text-xl font-bold">{item.branchName}</div>
          <div className="text-gray-600 overflow-hidden text-ellipsis whitespace-nowrap max-w-full">
            {item.category}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
