import { QRCode } from 'react-qrcode-logo';
import { useState, useEffect, useRef } from 'react';
const BaeonnBgLogo = require('../../../assets/background.png');

const BaeonnQR = ({ qr, size = null }) => {
  const [qrSize, setQrSize] = useState(size ? size : 150); // Default size for web view
  const qrCodeRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 640) {
        setQrSize(200); // Size for mobile view
      } else {
        setQrSize(size ? size : 150); // Size for web view
      }
    };

    // Set initial size
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="relative inline-block" ref={qrCodeRef}>
      <img src={BaeonnBgLogo} alt="BaeonnBg" className="w-full" />
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <QRCode
          value={qr}
          bgColor="white"
          fgColor="black"
          level="H"
          includeMargin={true}
          renderAs="canvas"
          size={qrSize} // Dynamic size based on screen width
        />
      </div>
    </div>
  );
};

export default BaeonnQR;
