const showToast = (message, type = 'success') => {
  // Create a container for toasts if it doesn't exist
  let toastContainer = document.getElementById('toast-container');
  if (!toastContainer) {
    toastContainer = document.createElement('div');
    toastContainer.id = 'toast-container';
    toastContainer.className =
      'fixed top-5 left-4 right-4 z-50 md:left-auto md:right-5 md:top-5 space-y-2';
    document.body.appendChild(toastContainer);
  }

  const toast = document.createElement('div');
  toast.className = `px-4 py-2 rounded text-white opacity-0 transition-opacity duration-500 ${type === 'success' ? 'bg-green-500' : 'bg-red-500'} flex items-center justify-center md:justify-start cursor-pointer`;

  const toastMessage = document.createElement('span');
  toastMessage.className = 'truncate'; // Ensure text does not overflow
  toastMessage.innerText = message;

  const closeButton = document.createElement('button');
  closeButton.className = 'ml-4 text-white';
  closeButton.innerHTML = '&times;'; // HTML entity for a cross (×)
  closeButton.onclick = () => {
    removeToast(toast);
  };

  toast.appendChild(toastMessage);
  toast.appendChild(closeButton);
  toastContainer.appendChild(toast);

  // Check the number of toasts
  const toasts = toastContainer.querySelectorAll('div:not(#remove-all-button)');
  if (toasts.length > 1) {
    // Ensure the "Remove All" button is always at the end
    let removeAllButton = document.getElementById('remove-all-button');
    if (!removeAllButton) {
      removeAllButton = document.createElement('button');
      removeAllButton.id = 'remove-all-button';
      removeAllButton.className =
        'px-4 py-2 bg-gray-500 text-white rounded mt-2 w-full';
      removeAllButton.innerText = 'Remove All';
      removeAllButton.onclick = removeAllToasts;
      toastContainer.appendChild(removeAllButton);
    } else {
      toastContainer.appendChild(removeAllButton);
    }
  }

  // Trigger the fade-in effect
  setTimeout(() => {
    toast.classList.add('opacity-100');
  }, 10); // Slight delay to ensure the class is applied

  // Remove the toast after 5 seconds if not closed manually
  setTimeout(() => {
    removeToast(toast);
  }, 5000); // Show for 5 seconds

  // Add drag functionality
  let startX;
  let currentX;
  let isDragging = false;

  toast.addEventListener('mousedown', (e) => {
    startX = e.clientX;
    isDragging = true;
    toast.style.transition = 'none';
  });

  document.addEventListener('mousemove', (e) => {
    if (!isDragging) return;
    currentX = e.clientX;
    const deltaX = currentX - startX;
    toast.style.transform = `translateX(${deltaX}px)`;
  });

  document.addEventListener('mouseup', () => {
    if (!isDragging) return;
    isDragging = false;
    toast.style.transition = 'transform 0.5s ease-in-out';
    const deltaX = currentX - startX;
    if (Math.abs(deltaX) > 100) {
      removeToast(toast);
    } else {
      toast.style.transform = 'translateX(0)';
    }
  });
};

const removeToast = (toast) => {
  toast.classList.remove('opacity-100');
  setTimeout(() => {
    if (toast.parentElement) {
      toast.parentElement.removeChild(toast);
    }
    // Check if there are no more toasts and remove the "Remove All" button
    const toastContainer = document.getElementById('toast-container');
    const toasts = toastContainer.querySelectorAll(
      'div:not(#remove-all-button)'
    );
    if (toasts.length === 0) {
      const removeAllButton = document.getElementById('remove-all-button');
      if (removeAllButton) {
        removeAllButton.parentElement.removeChild(removeAllButton);
      }
    }
  }, 500); // Wait for the fade-out transition to complete
};

const removeAllToasts = () => {
  const toastContainer = document.getElementById('toast-container');
  if (toastContainer) {
    while (toastContainer.firstChild) {
      toastContainer.removeChild(toastContainer.firstChild);
    }
  }
};

export default showToast;

// Usage examples:
// showToast("Redirecting to QR Generation page...", "success");
// showToast("An error occurred. Please try again.", "error");
