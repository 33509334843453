import { Card, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  card: (props) => ({
    width: '100%',
    boxShadow: '0 3px 20px rgb(0 0 0 / 0.2)',
    //   minHeight: "100vh",
    background: props.color ? props.color : '',
    margin: 'auto',
    //   marginTop: "100px",
    borderRadius: '5px',
    padding: '20px',
    border: `10px solid ${props?.borderColor ? props?.borderColor : 'none'}`,
  }),
}));

export default function WrapperCard(prop) {
  const classes = useStyles({ color: prop.bg, borderColor: prop.borderColor });

  return (
    <>
      <Card className={classes.card}>{prop.children}</Card>
    </>
  );
}
