import { Box, Card, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import WrapperCard from '../../components/wrapperCard/wrapperCard';
import MainLayout from '../../layout/mainLayout';
import {
  getStoreDetail,
  getStoreDetailforManager,
  getUserDetails,
} from '../../service_config/admin.service';
import { getCurrentUser } from '../../service_config/auth.service';
import { BUSINESSOWNER } from '../../utilities/constants';

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: '100%',
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
    minHeight: '100vh',
    margin: 'auto',
    marginTop: '100px',
    borderRadius: '5px',
    padding: '20px',
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');

  const handleChange = (name, action = '') => {
    if (name === 'store') {
      navigate(`/create-store/add-store`);
    } else if (name === 'bulkinvite') {
      navigate(`/bulkinvite`, {
        state: { action: action === 'franchise' ? 'franchise' : 'chain' },
      });
    } else if (name === 'createCampaign') {
      navigate(`/createCampaign`);
    } else if (name === 'viewCampaigns') {
      navigate(`/viewCampaigns`);
    } else if (name === 'shareCoupons') {
      navigate(`/shareCoupons`);
    } else if (name === 'claimCoupons') {
      navigate(`/claimCoupons`);
    }
  };
  React.useEffect(() => {
    getUserDetails();
    if (getCurrentUser().role.name === BUSINESSOWNER) {
      getStoreDetail();
    } else {
      getStoreDetailforManager({ id: getCurrentUser().storeId });
    }
  }, []);
  return (
    <MainLayout>
      <Card variant="outlined" className={classes.card}>
        <Grid container spacing={2} columns={{ xs: 2, md: 12 }}>
          <>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  textAlign: 'center',
                  padding: '10px',
                  fontSize: '1.5em',
                }}
              >
                Add store
              </Box>

              <WrapperCard bg="#2a4987">
                <Box
                  sx={{
                    textAlign: 'center',
                    padding: '10px',
                    color: 'white',
                    fontSize: '1.5em',
                  }}
                  onClick={() => handleChange('store')}
                >
                  One by one
                </Box>
              </WrapperCard>
              <Box
                sx={{
                  marginTop: '10px',
                }}
                onClick={() => handleChange('bulkinvite', 'chain')}
              >
                <WrapperCard bg="#2a4987">
                  <Box
                    sx={{
                      textAlign: 'center',
                      padding: '10px',
                      color: 'white',
                      fontSize: '1.5em',
                    }}
                    onClick={() => handleChange('bulkinvite', 'chain')}
                  >
                    Bulk Invite
                  </Box>
                </WrapperCard>
              </Box>

              {/* </WrapperCard> */}
            </Grid>

            <Grid item xs={12} md={6}>
              {/* <WrapperCard bg="#FFFFF"> */}
              <Box
                sx={{
                  textAlign: 'center',
                  padding: '10px',

                  fontSize: '1.5em',
                }}
              >
                Add Franchisee
              </Box>

              <WrapperCard bg="#2a4987">
                <Box
                  sx={{
                    textAlign: 'center',
                    padding: '10px',
                    color: 'white',
                    fontSize: '1.5em',
                  }}
                  onClick={() => handleChange('bulkinvite', 'franchise')}
                >
                  Bulk Invite
                </Box>
              </WrapperCard>

              {/* </WrapperCard> */}
            </Grid>
          </>
        </Grid>
      </Card>
    </MainLayout>
  );
}
