import {
  Card,
  Chip,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import Lable from '../../components/Typography/lable';
import MainLayout from '../../layout/mainLayout';

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: '1000px',
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
    minHeight: '100vh',
    margin: '0px auto',
    marginTop: '100px',
    borderRadius: '5px',
    padding: '20px',
  },
  grid: {
    padding: '25px 0',
  },
  lable: {
    fontSize: '1.30rem',
    lineHeight: '1rem',
    fontWeight: 'bold',
    color: '#00070d',
  },
}));

export default function ShareCoupons() {
  const classes = useStyles();
  const stores = useSelector((state) => state.storeDetail.stores);
  const navigate = useNavigate(); // Initialize useNavigate hook

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleStoreSelect = (selectedStoreId) => {
    navigate('/shareCoupon');
  };

  return (
    <MainLayout>
      <form>
        <Card variant="outlined" className={classes.card}>
          <Grid
            container
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            className={classes.grid}
          >
            <Grid item>
              <Typography
                variant="h6"
                display="block"
                sx={{
                  lineHeight: '1rem',
                  fontWeight: 'bold',
                  color: '#1c1c1c',
                }}
                className={classes.lable}
              >
                Available Campaign
              </Typography>
            </Grid>
          </Grid>{' '}
          <Stack>
            <Lable
              style={{ my: 2, marginRight: 'auto' }}
              title="SELECT STORE"
            />
            <Controller
              name={'storeId'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <Select
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    fullWidth
                    variant="outlined"
                    value={value || 'Select Store'}
                    onChange={(e) => {
                      onChange(e);
                      handleStoreSelect(e.target.value); // Call handleStoreSelect on store selection change
                    }}
                  >
                    <MenuItem value="Select Store">
                      <em>Select Store</em>
                    </MenuItem>
                    {stores?.map((store, index) => (
                      <MenuItem value={store?.id} key={index}>
                        {store?.branchName}
                      </MenuItem>
                    ))}
                  </Select>{' '}
                </>
              )}
            />
            {errors.storeId && (
              <Typography color="error">{errors.storeId.message}</Typography>
            )}
          </Stack>
          <>
            <Box style={{ textAlign: 'center', marginTop: '20px' }}>
              <Chip
                label="No Near by stores found"
                color="primary"
                variant="outlined"
              />
            </Box>
          </>
        </Card>
      </form>
    </MainLayout>
  );
}
