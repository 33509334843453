import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CardShareCoupons from '../../components/Cards/newCardCampaigns';
import MainLayout from '../../layout/mainLayout';
import {
  getDealPoolCamaignByStoreId,
  getStoreDetailByPhoneNumber,
  viewAssigedCampaignlist,
  viewCampaignlist,
} from '../../service_config/admin.service';
import { getCurrentUser } from '../../service_config/auth.service';
import { STOREMANAGER } from '../../utilities/constants';

import Typography from '@mui/material/Typography';

import Chip from '@mui/material/Chip';

import Box from '@mui/material/Box';

import { Card, MenuItem, Select } from '@material-ui/core';
import Stack from '@mui/material/Stack';
import Lable from '../../components/Typography/lable';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';

const useStyles = makeStyles((theme) => ({
  cards: {
    marginTop: '100px',
  },
}));

export default function ViewCampaigns() {
  const classes = useStyles();
  const viewCampaign = useSelector(
    (state) => state.campaignDetails.viewCampaign
  );
  const stores = useSelector((state) => state.storeDetail.stores);
  const [dealPoolCampaigns, setDealPoolCampaigns] = useState([]);
  const [storeId, setStoreId] = useState('');
  const [rows, setRows] = React.useState([]);
  React.useEffect(() => {
    if (getCurrentUser().role.name === STOREMANAGER) {
      viewAssigedCampaignlist();
    } else {
      viewCampaignlist();
    }
  }, []);

  const [value, setValue] = React.useState('1');

  const getDealPoolCampaign = async (storeId) => {
    const campaigns = await getDealPoolCamaignByStoreId(storeId);
    if (!campaigns?.error) {
      setDealPoolCampaigns(campaigns?.result);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const onSelectStoreId = (e) => {
    setStoreId(e.target.value);
  };
  useEffect(() => {
    if (getCurrentUser().role.name === STOREMANAGER) {
      getStoreDetailByPhoneNumber();
    }
  }, []);
  useEffect(() => {
    if (stores?.length > 0 && getCurrentUser().role.name === STOREMANAGER) {
      setStoreId(stores?.[0]?.id);
    }
  }, [stores]);
  useEffect(() => {
    if (storeId && getCurrentUser().role.name === STOREMANAGER) {
      getDealPoolCampaign(storeId);
    } else {
      getDealPoolCampaign('');
    }
  }, [storeId]);

  return (
    <>
      <MainLayout>
        <Card variant="outlined" className={classes.card}>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Deal pool" value="1" />
                  <Tab label="Target Campaigns" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <>
                  <Typography
                    variant="h6"
                    display="block"
                    sx={{
                      // fontSize: "12px",
                      lineHeight: '1rem',
                      fontWeight: 'bold',
                      color: '#1c1c1c',
                    }}
                    className={classes.lable}
                  >
                    Deal pool Campaigns
                  </Typography>

                  {getCurrentUser().role.name === STOREMANAGER ? (
                    <Stack>
                      <Lable
                        style={{ my: 2, marginRight: 'auto' }}
                        title="SELECT STORE VIEW CAMPAIGN"
                      />
                      <Select
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        fullWidth
                        variant="outlined"
                        value={storeId}
                        onChange={onSelectStoreId}
                      >
                        <MenuItem value="">
                          <em>Select Store</em>
                        </MenuItem>
                        {stores?.map((store, index) => (
                          <MenuItem value={store?.id} key={index}>
                            {store?.branchName}
                          </MenuItem>
                        ))}
                      </Select>{' '}
                    </Stack>
                  ) : (
                    ''
                  )}
                  {dealPoolCampaigns?.length > 0 ? (
                    <div className={classes.cards}>
                      <CardShareCoupons
                        rows={dealPoolCampaigns}
                        action="dealPool"
                      />
                    </div>
                  ) : (
                    <>
                      <Box
                        style={{
                          textAlign: 'center',
                          marginTop: '20px',
                          width: '100%',
                        }}
                      >
                        <Chip
                          label="No data found"
                          color="primary"
                          variant="outlined"
                        />
                      </Box>
                    </>
                  )}
                </>
              </TabPanel>
              <TabPanel label="Deal pool" value="2">
                <>
                  <Typography
                    variant="h6"
                    display="block"
                    sx={{
                      // fontSize: "12px",
                      lineHeight: '1rem',
                      fontWeight: 'bold',
                      color: '#1c1c1c',
                    }}
                    className={classes.lable}
                  >
                    Targeted Campaigns
                  </Typography>

                  {viewCampaign?.length > 0 ? (
                    <div className={classes.cards}>
                      <CardShareCoupons rows={viewCampaign} action="pay" />
                    </div>
                  ) : (
                    <>
                      <Box
                        style={{
                          textAlign: 'center',
                          marginTop: '20px',
                          width: '100%',
                        }}
                      >
                        <Chip
                          label="No data found"
                          color="primary"
                          variant="outlined"
                        />
                      </Box>
                    </>
                  )}
                </>
              </TabPanel>
            </TabContext>
          </Box>
        </Card>
      </MainLayout>
    </>
  );
}
