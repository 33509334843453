import React, { useEffect, useState } from 'react';

import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { yupResolver } from '@hookform/resolvers/yup';
import MuiPhoneNumber from 'material-ui-phone-number';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import ErrorField from '../../components/errorField';
import FileUploadHoc from '../../components/File.Upload';
import Lable from '../../components/Typography/lable';
import {
  getCategoryLists,
  getStoreDetailById,
  getUserDetails,
  submitStoreDetail,
  updateStoreDetailById,
} from '../../service_config/admin.service';
import { getCurrentUser } from '../../service_config/auth.service';
import { BUSINESSOWNER, STOREMANAGER } from '../../utilities/constants';
import {
  hoursList,
  storeFormArray,
  storeInitialState,
  storesSchema,
} from './formValues/store';

import {
  Button,
  Card,
  Grid,
  Input,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import BackupIcon from '@mui/icons-material/Backup';
import MainLayout from '../../layout/mainLayout';

import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const useStyles = makeStyles((theme) => ({
  lable: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontWeight: 'bold',
    color: '#57748a',
  },
  card: {
    maxWidth: '1000px',
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
    // minHeight: "100vh",
    margin: '0px auto',
    marginTop: '100px',
    borderRadius: '5px',
    padding: '20px',
  },
  cancel: {
    background: '#152894',
    color: '#f7f8fa',
    fontWeight: '700',
    '&:hover': {
      background: '#152894',
      color: '#f7f8fa',
    },
  },
  loadingBtn: {
    color: 'rgba(0, 0, 0, 0.26)',
    background: 'none',
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },
  inputfile: {
    display: 'none',
  },
  upload: {
    background: '#f0f3f5',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  icon: {
    transform: 'rotate(180deg)',
  },
  grid: {
    padding: '25px 0',
  },
  input: {
    border: 'none',
    boxShadow: '0 3px 5px rgb(0 0 0 / 0.1)',
    borderRadius: '10px',
    background: 'white',
    '&:hover': {
      border: 'none',
      outline: 'none',
    },
  },
  radio: {
    '&$checked': {
      color: '#4B8DF8',
    },
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  checked: {},
  edit: {},
  checkbox: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontWeight: '700',
    color: '#1c1c1c',
  },
}));

function AddStore(props) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [seletedDays, setSelectedDays] = useState([]);
  const userDetail = useSelector((state) => state?.userDetail?.userDetail);

  const [action, setAction] = useState('Add');
  let weeksArray = [
    { lable: 'Monday', value: 'Monday' },
    { lable: 'Tuesday', value: 'Tuesday' },
    { lable: 'Wednesday', value: 'Wednesday' },
    { lable: 'Thrusday', value: 'Thrusday' },
    { lable: 'Friday', value: 'Friday' },
    { lable: 'Saturday', value: 'Saturday' },
    { lable: 'Sunday', value: 'Sunday' },
  ];

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const store_id = queryParams.get('store_id');

  const initialState = [];

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    getValues,
    watch,
    reset,
    formState: { errors, values },
  } = useForm({
    defaultValues: { ...storeInitialState },
    resolver: yupResolver(storesSchema),
    mode: 'onBlur',
  });

  const storeDetails = watch();
  const id = storeDetails?.id;

  // if store then don't show add store on load else show;
  // { day: "Monday", from: "00:00", to: "00:00" }
  const [newDateTimeSlot, setNewDateTimeSlot] = React.useState(initialState);
  const [selectedTime, setSelectTime] = React.useState({ from: '', to: '' });
  const [formInput, setFormInput] = React.useState(storeFormArray);
  const [formSubmitLoading, setFormSubmitLoading] = React.useState(false);

  const onSelectTime = (name, value) => {
    setSelectTime((pre) => ({ ...pre, [`${name}`]: value }));
  };
  useEffect(() => {}, [userDetail]);

  const getCategory = async () => {
    let data = await getCategoryLists();
    if (!data.error) {
      let getLables = data.result.map((item) => item.name);
      let copyFormArrary = [...storeFormArray].map((item) => {
        if (item.grid) {
          let newItem = item.items.map((inputObj) => {
            if (inputObj.name === 'category') {
              return { ...inputObj, listData: getLables };
            }
            return inputObj;
          });
          return { ...item, items: newItem };
        }
        return item;
      });
      setFormInput(copyFormArrary);
    }
  };
  const getEditStoreDetails = async () => {
    const data = await getStoreDetailById({ id: state?.id });
    if (!data.error) {
      reset(data.result);
      if (data.result?.workingHours?.length > 0) {
        let getDays = data.result?.workingHours.map((item) => item.day);
        setSelectedDays(getDays);
      }
    }
  };
  React.useEffect(() => {
    // getSgetCategorytoreDetail();
    getCategory();
    getUserDetails();

    if (state?.id) {
      getEditStoreDetails();
      setAction('Edit');
    }
    if (storeDetails?.workingHours?.length > 0) {
      let getDays = storeDetails?.workingHours.map((item) => item.day);
      setSelectedDays(getDays);
    }
    localStorage.removeItem('oldEmail');
    localStorage.removeItem('oldNumber');
  }, []);
  const toogleInput = (value) => {
    let copyFormArrary = [...formInput].map((item) => {
      if (item.grid) {
        let newItem = item.items.map((inputObj) => {
          if (inputObj.name === 'email') {
            return { ...inputObj, disabled: value };
          }
          return inputObj;
        });
        return { ...item, items: newItem };
      }
      return item;
    });
    setFormInput(copyFormArrary);
  };
  const handlechangeCheckbox = (name, value) => {
    if (!value) {
      switch (name) {
        case 'email':
          if (action === 'Edit') {
            setValue('email', userDetail?.email);
            toogleInput(false);
          } else {
            setValue('email', '');
            toogleInput(false);
          }

          break;
        case 'phone':
          if (action === 'Edit') {
            setValue(
              'contactNumber',
              JSON.parse(localStorage.getItem('oldNumber'))
            );
          } else {
            setValue('contactNumber', '');
          }
          break;

        default:
          break;
      }
    } else {
      switch (name) {
        case 'email':
          const ownersEmail = userDetail?.email;
          setValue('email', ownersEmail);
          toogleInput(true);
          break;

        case 'phone':
          localStorage.setItem(
            'oldNumber',
            JSON.stringify(storeDetails.contactNumber)
          );
          const mobileNumber = getCurrentUser().mobileNumber;
          setValue('contactNumber', mobileNumber);
          break;

        default:
          break;
      }
    }
  };

  const addDayTime = (dayTimeSlots) => {
    setError('workingHours', { type: 'custom', message: '' });
    setValue('workingHours', dayTimeSlots);
  };

  const removeDayTime = (dayTimeSlots) => {
    setValue('workingHours', dayTimeSlots);
  };

  const removeDayTimeSlot = (day) => {
    let newSlot = storeDetails.workingHours.filter((slot) => day !== slot.day);
    let removeChecked = [...seletedDays].filter((item) => item !== day);
    setSelectedDays(removeChecked);
    removeDayTime(newSlot);
  };
  const addSlot = (e) => {
    e.preventDefault();
    if (!selectedTime.from || !selectedTime.to) return;
    let filterValue = [...storeDetails.workingHours].map((slot, index) => {
      if (seletedDays.includes(slot.day)) {
        return slot.day;
      }
    });
    let finalArr = [];
    let finalValue = [...seletedDays].map((item) => {
      if (!filterValue.includes(item)) {
        finalArr.push(item);
      }
    });
    let updateFromAndTo = [...finalArr].map((item) => ({
      day: item,
      from: selectedTime.from,
      to: selectedTime.to,
    }));
    addDayTime([...storeDetails.workingHours, ...updateFromAndTo]);
  };
  const setdays = (value, checked) => {
    if (seletedDays.includes(value) || !checked) {
      removeDayTimeSlot(value);
      return;
    }
    let updatevalue = [...seletedDays, value];
    setSelectedDays(updatevalue);
  };

  const setimageUrl = (data, fileType) => {
    switch (fileType) {
      case 'storeImage':
        setValue('imageUrl', data);
        setError('imageUrl', { type: 'custom', message: '' });
        break;
      case 'storeLogo':
        setValue('logoUrl', data);
        setError('logoUrl', { type: 'custom', message: '' });
        break;
      default:
        break;
    }
  };

  const handleSubmitFormData = async (type) => {
    switch (type) {
      case 'add':
        setFormSubmitLoading(true);
        const res = await submitStoreDetail(storeDetails);

        if (!res.error) {
          setFormSubmitLoading(false);
          toast.success(res.msg);
          navigate('/store');
        } else {
          setFormSubmitLoading(false);
          toast.error(res.msg);
        }

        break;
      case 'update':
        setFormSubmitLoading(true);
        const response = await updateStoreDetailById({
          id: id,
          body: storeDetails,
        });

        if (!response.error) {
          setFormSubmitLoading(false);
          toast.success(response.msg);
          navigate('/store');
        } else {
          setFormSubmitLoading(false);
          toast.error(response.msg);
        }
        break;
      default:
        break;
    }
  };

  const classes = useStyles();
  const onSubmit = () => {
    let actionForm = storeDetails?.id ? 'update' : 'add';
    handleSubmitFormData(actionForm);
  };

  return (
    <MainLayout>
      <Card variant="outlined" className={classes.card}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            className={classes.grid}
          >
            <Grid item xs={12} md={6}>
              <Typography
                variant="span"
                display="inline"
                sx={{
                  // fontSize: "12px",
                  lineHeight: '0.5rem',
                  fontWeight: 'normal',
                  color: 'red',
                }}
                className={classes.lable}
              >
                {getCurrentUser().role.name === STOREMANAGER
                  ? 'YOU ARE ALLOWED TO EDIT THIS ONLY ONCE OR CONTACT BUSSINESS OWNER'
                  : ''}
              </Typography>
              <Typography
                variant="h6"
                display="block"
                sx={{
                  // fontSize: "12px",
                  lineHeight: '1rem',
                  fontWeight: 'bold',
                  color: '#1c1c1c',
                }}
                className={classes.lable}
              >
                {`${action} store details`}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}></Grid>
          </Grid>
          <Stack spacing={2} sx={{ py: 2 }}>
            {formInput.map((data, index) => {
              let { grid, items } = data;

              if (grid) {
                return (
                  <div key={index}>
                    <Grid container spacing={2}>
                      {items.map((field) => {
                        let error = field?.nestedObj
                          ? errors[field?.name?.split('.')[0]]?.[
                              field?.name?.split('.')[1]
                            ]
                            ? true
                            : false
                          : errors[field.name]
                            ? true
                            : false;
                        let errorMessage = field?.nestedObj
                          ? errors[field?.name?.split('.')[0]]?.[
                              field?.name?.split('.')[1]
                            ]
                          : errors[field.name];

                        if (field.type === 'input') {
                          return (
                            <>
                              <Grid item xs={field.xs} md={field.md}>
                                <Lable
                                  style={{ my: 2, marginRight: 'auto' }}
                                  title={field.lable}
                                />
                                {field?.checkBox && (
                                  <>
                                    <Box>
                                      {' '}
                                      <FormControlLabel
                                        className={classes.checkbox}
                                        sx={{
                                          fontSize: '0.75rem',
                                          lineHeight: '1rem',
                                          fontWeight: '700',
                                          color: '#1c1c1c',
                                        }}
                                        control={
                                          <Checkbox
                                            checked={
                                              userDetail?.email &&
                                              storeDetails.email ===
                                                userDetail?.email
                                                ? true
                                                : false
                                            }
                                            onChange={(event) =>
                                              handlechangeCheckbox(
                                                'email',
                                                event.target.checked
                                              )
                                            }
                                            inputProps={{
                                              'aria-label': 'controlled',
                                            }}
                                          />
                                        }
                                        label={field.checBoxLable}
                                      />
                                    </Box>
                                  </>
                                )}

                                <Controller
                                  name={field.name}
                                  control={control}
                                  render={({ field: { onChange, value } }) => (
                                    <TextField
                                      id="outlined-basic"
                                      variant="outlined"
                                      name={field.name}
                                      type={field.inputType}
                                      value={value}
                                      fullWidth
                                      className={classes.input}
                                      disabled={field.disabled ? true : false}
                                      placeholder={field.placeholder}
                                      // InputProps={{ classes: { underline: classes.input } }}
                                      sx={{
                                        border: 'none',
                                      }}
                                      error={error}
                                      onChange={onChange}
                                    />
                                  )}
                                />
                                {error && (
                                  <ErrorField err={errorMessage?.message} />
                                )}
                              </Grid>
                            </>
                          );
                        }
                        if (field.type === 'select') {
                          return (
                            <>
                              <Grid item xs={field.xs} md={field.md}>
                                <Lable
                                  style={{ my: 2, marginRight: 'auto' }}
                                  title={field.lable}
                                />

                                <Controller
                                  name={field.name}
                                  control={control}
                                  render={({ field: { onChange, value } }) => (
                                    <Select
                                      value={value}
                                      // onChange={handleChange}
                                      displayEmpty
                                      inputProps={{
                                        'aria-label': 'Without label',
                                      }}
                                      fullWidth
                                      variant="outlined"
                                      error={error}
                                      onChange={onChange}
                                    >
                                      <MenuItem value="">
                                        <em>None</em>
                                      </MenuItem>
                                      {field.listData.map((option, index) => (
                                        <MenuItem value={option} key={index}>
                                          {option}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  )}
                                />
                                {error && (
                                  <ErrorField err={errorMessage?.message} />
                                )}
                              </Grid>
                            </>
                          );
                        }

                        if (field.type === 'phone') {
                          return (
                            <>
                              <Grid item xs={field.xs} md={field.md}>
                                <Lable
                                  style={{ my: 2, marginRight: 'auto' }}
                                  title={field.lable}
                                />
                                <Box>
                                  {' '}
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          storeDetails.contactNumber ===
                                          getCurrentUser().mobileNumber
                                            ? true
                                            : false
                                        }
                                        onChange={(event) =>
                                          handlechangeCheckbox(
                                            'phone',
                                            event.target.checked
                                          )
                                        }
                                        inputProps={{
                                          'aria-label': 'controlled',
                                        }}
                                      />
                                    }
                                    label="same as Bussiness owners Phone Number"
                                  />
                                </Box>

                                <Controller
                                  name={field.name}
                                  control={control}
                                  render={({ field: { onChange, value } }) => (
                                    <MuiPhoneNumber
                                      name="phone"
                                      id="phone"
                                      // data-cy="user-phone"
                                      disabled={
                                        value === getCurrentUser().mobileNumber
                                      }
                                      defaultCountry={'in'}
                                      fullWidth
                                      variant="outlined"
                                      value={value}
                                      placeHolder={field.placeholder}
                                      sx={{
                                        border: 'none',
                                      }}
                                      error={error}
                                      onChange={onChange}
                                    />
                                  )}
                                />
                                {error && (
                                  <ErrorField err={errorMessage?.message} />
                                )}
                              </Grid>
                            </>
                          );
                        }
                        if (field.type === 'fileUpload') {
                          return (
                            <>
                              <Grid item xs={field.xs} md={field.md}>
                                <FileUploadHoc
                                  fileType={field.id}
                                  set={setimageUrl}
                                  storeId={store_id}
                                >
                                  <Box
                                    component="label"
                                    sx={{
                                      width: '90%',
                                      margin: '0 auto',
                                      padding: '10px',
                                      background: 'white',
                                      height: '100px',
                                      display: 'block',
                                      boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
                                    }}
                                    htmlFor={field.id}
                                  >
                                    <Input
                                      accept="image/*"
                                      id={field.id}
                                      type="file"
                                      className={classes.inputfile}
                                    />
                                    <IconButton
                                      color="primary"
                                      aria-label="upload picture"
                                      component="span"
                                      className={classes.upload}
                                    >
                                      <BackupIcon
                                        sx={{
                                          fontSize: '40px',
                                        }}
                                      />

                                      <Lable
                                        style={{ my: 2, textAlign: 'center' }}
                                        title={field.label}
                                      />
                                    </IconButton>
                                  </Box>
                                  {error && (
                                    <ErrorField err={errorMessage?.message} />
                                  )}
                                </FileUploadHoc>
                                {storeDetails[field?.name]
                                  ? 'Last uploaded:'
                                  : ''}{' '}
                                <span>
                                  {storeDetails[field?.name] ? (
                                    <a
                                      href={storeDetails[field?.name]}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      Click Here
                                    </a>
                                  ) : (
                                    ''
                                  )}
                                </span>
                              </Grid>
                            </>
                          );
                        }

                        return <></>;
                      })}
                    </Grid>
                  </div>
                );
              } else if (!grid) {
                return (
                  <div key={index}>
                    <Lable
                      style={{ my: 2, marginRight: 'auto' }}
                      title={data?.lable}
                    />
                    <Divider />
                  </div>
                );
              }

              return <></>;
            })}
            <div>
              <FormControl
                sx={{ m: 3 }}
                component="fieldset"
                variant="standard"
              >
                <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                  {weeksArray.map((item, index) => {
                    return (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            checked={seletedDays.includes(item.value)}
                            onChange={(e) =>
                              setdays(e.target.name, e.target.checked)
                            }
                            name={item.value}
                          />
                        }
                        label={item.value}
                        // disabled={seletedDays.includes(item.value)}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            </div>
            <div>
              <Grid container spacing={2} columns={{ xs: 4, md: 12 }}>
                <Grid item xs={12} md={4}>
                  <div>
                    <Lable
                      style={{ my: 2, marginRight: 'auto' }}
                      title="FROM"
                    />
                    <Select
                      value={newDateTimeSlot.from}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      fullWidth
                      variant="outlined"
                      defaultValue=""
                      onChange={(e) => {
                        // setNewDateTimeSlot({
                        //   ...newDateTimeSlot,
                        //   from: e.target.value,
                        // });
                        onSelectTime('from', e.target.value);
                      }}
                    >
                      <MenuItem value="">
                        <em>SELECT TIME</em>
                      </MenuItem>
                      {hoursList.map((item, index) => {
                        return (
                          <MenuItem value={item} key={index}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>{' '}
                  </div>
                </Grid>
                <Grid item xs={12} md={4}></Grid>
                <Grid item xs={12} md={4}>
                  <div>
                    <Lable style={{ my: 2, marginRight: 'auto' }} title="TO" />
                    <Select
                      value={newDateTimeSlot.to}
                      // onChange={handleChange}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      fullWidth
                      variant="outlined"
                      defaultValue=""
                      onChange={(e) => {
                        onSelectTime('to', e.target.value);
                      }}
                    >
                      <MenuItem value="">
                        <em>SELECT TIME</em>
                      </MenuItem>
                      {hoursList.map((item, index) => {
                        return (
                          <MenuItem value={item} key={index}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>{' '}
                  </div>
                </Grid>
              </Grid>
            </div>
            <div>
              <Button
                color="success"
                size="medium"
                onClick={addSlot}
                className={classes.cancel}
              >
                Add slot
              </Button>
            </div>
            <div>
              {errors['workingHours'] && (
                <ErrorField err={errors['workingHours']?.message} />
              )}
            </div>
            <Box sx={{ width: { xs: '300', sm: '500' }, overflowX: 'scroll' }}>
              <TableContainer
                component={Paper}
                sx={{
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'start',
                }}
              >
                <Table
                  sx={{ maxWidth: 700, textAlign: 'start' }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          fontSize: '12px',
                          lineHeight: '1rem',
                          fontWeight: 'bold',
                          color: '#1c1c1c',
                        }}
                      >
                        DAY
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          fontSize: '12px',
                          lineHeight: '1rem',
                          fontWeight: 'bold',
                          color: '#1c1c1c',
                        }}
                      >
                        TIME
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          fontSize: '12px',
                          lineHeight: '1rem',
                          fontWeight: 'bold',
                          color: '#1c1c1c',
                        }}
                      >
                        ACTION
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {storeDetails?.workingHours?.length > 0 &&
                      storeDetails?.workingHours?.map((slot) => (
                        <TableRow
                          key={slot.name}
                          sx={{
                            '&:last-child td, &:last-child th': {
                              border: 'none',
                            },
                          }}
                        >
                          <TableCell align="center">{slot.day}</TableCell>
                          <TableCell align="center">
                            {slot.from}-{slot.to}
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              color="success"
                              size="medium"
                              className={classes.cancel}
                              onClick={(e) => removeDayTimeSlot(slot.day)}
                            >
                              Remove slot
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Stack>

          {getCurrentUser().role.name === STOREMANAGER && state.isEdit && (
            <Box sx={{ textAlign: 'center' }}>
              <Button
                color="success"
                size="small"
                type="submit"
                onClick={(e) =>
                  handleSubmit(e, action === 'add' ? 'Add' : 'update')
                }
                className={classes.cancel}
              >
                {action === 'Add' ? 'Add' : 'update'}
              </Button>
              :
            </Box>
          )}
          {getCurrentUser().role.name === BUSINESSOWNER && (
            <Box sx={{ textAlign: 'center' }}>
              {formSubmitLoading ? (
                <LoadingButton
                  type="submit"
                  className={classes.loadingBtn}
                  loading={formSubmitLoading}
                  loadingPosition="start"
                  variant="outlined"
                  startIcon={<SaveIcon />}
                >
                  {action === 'Add' ? 'Add' : 'update'}
                </LoadingButton>
              ) : (
                <Button
                  color="success"
                  type="submit"
                  className={classes.cancel}
                >
                  {action === 'Add' ? 'Add' : 'update'}
                </Button>
              )}
            </Box>
          )}
        </form>
      </Card>
    </MainLayout>
  );
}

export default AddStore;
