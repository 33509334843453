import React, { useEffect, useState } from 'react';
import { getNearBuyStores } from '../../../service_config/admin.service';
import StoreCard from './card';
import SearchBar from './SearchBar';
import Loading from './loader';

export default function NearBuyStore() {
  const [storeDetail, setStoreDetails] = useState([]);
  const [value, setValue] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getStoresDetails();
  }, [value]);

  const getStoresDetails = async () => {
    if (value) {
      setLoading(true);
      const data = await getNearBuyStores({ pincode: value });

      if (!data.error && data.result?.length > 0) {
        setLoading(false);
        setStoreDetails(data.result);
        setError('');
      } else if (data.msg === 'No store found') {
        setLoading(false);
        setStoreDetails([]);
        setError(data.msg);
      } else {
        setLoading(false);
        setError('');
      }
    }
  };

  const onSubmit = (value) => {
    setValue(value);
  };

  return (
    <div className="min-h-screen flex flex-col items-center pt-8">
      <SearchBar onSubmit={onSubmit} />

      {loading && <Loading />}
      {storeDetail && storeDetail.length > 0 && (
        <div className="mt-4 w-full max-w-6xl grid grid-cols-1 md:grid-cols-2 gap-4">
          {storeDetail.map((item) => (
            <StoreCard key={item.id} item={item} />
          ))}
        </div>
      )}
    </div>
  );
}
