import { useState, useEffect } from 'react';

const SearchBar = ({ onSubmit }) => {
  const [inputValue, setInputValue] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, 300); // 300ms debounce delay

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);

  useEffect(() => {
    if (debouncedValue) {
      onSubmit(debouncedValue);
    }
  }, [debouncedValue, onSubmit]);

  return (
    <div className="w-full max-w-md">
      <div className="flex items-center bg-white rounded-full shadow-md p-3">
        <input
          onChange={(e) => setInputValue(e.target.value)}
          className="flex-grow outline-none px-4 py-2 rounded-l-full"
          type="text"
          placeholder="Search..."
        />
        <button className="flex items-center justify-center h-10 w-10 bg-blue-500 text-white rounded-full">
          <i className="fas fa-search"></i>
        </button>
      </div>
    </div>
  );
};

export default SearchBar;
