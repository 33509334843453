import Typography from '@mui/material/Typography';
import React from 'react';

// const Label = styled.label`
//   color: red;
//   font-size: 12px;
//   margin: 0;
//   display: block;
// `;

export default function ErrorField({ err, style }) {
  return (
    <>
      <Typography
        sx={{
          fontSize: '12px',
          margin: '0',
          color: 'red',
          ...style,
        }}
        variant="lable"
        color="text.secondary"
      >
        {err?.charAt(0)?.toUpperCase() + err?.slice(1)?.replaceAll('_', ' ')}
      </Typography>
    </>
  );
}
